import React, { useRef, useState } from 'react';
import { motion, useInView, useScroll, useTransform } from 'framer-motion';
import { DroneforgeButton } from '../DroneforgeButton.tsx';
import { FaInfoCircle } from 'react-icons/fa';
import WaitlistForm from '../WaitlistForm.tsx';

export const BufferSection: React.FC = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const [showWaitlist, setShowWaitlist] = useState(false);
  
  return (
    <section ref={sectionRef} className="w-full pt-2 pb-4 bg-white relative overflow-hidden">
      {/* Subtle background pattern */}
      <div className="absolute inset-0 opacity-[0.06]">
        <div 
          className="h-full w-full" 
          style={{
            backgroundImage: `radial-gradient(circle, rgba(0, 241, 53, 0.5) 1px, transparent 1px)`,
            backgroundSize: '40px 40px',
          }}
        />
      </div>
      
      {/* Very subtle glow effects - pre-rendered with no animations */}
      <div className="absolute top-1/4 left-1/4 w-96 h-96 rounded-full bg-[#00F135]/5 filter blur-[120px] opacity-[0.15]" />
      <div className="absolute bottom-1/4 right-1/4 w-80 h-80 rounded-full bg-[#00F135]/5 filter blur-[100px] opacity-[0.1]" />
      
      <div className="w-[95%] max-w-[95%] mx-auto relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10">
          {/* Nimbus */}
          <ProductCard 
            imageSrc="/webp_converted/nimbus.webp" 
            title="Nimbus"
            price="$150"
            description="Intelligent hardware platform and SDK that transforms any drone into a smart system."
            detailedInfo="DroneForge Nimbus is the world's first plug-and-play AI system for drones that seamlessly transforms any drone into an intelligent autonomous asset. Simply connect your Mac to your drone, deploy advanced AI capabilities, and start collecting actionable insights instantly. No coding skills or specialized hardware required."
            onWaitlist={() => setShowWaitlist(true)}
            showExplore={true}
          />
          
          {/* The Forge */}
          <ProductCard 
            imageSrc="/webp_converted/theforge.webp" 
            title="The Forge"
            price=""
            description="Community platform to download and share Nimbus projects."
            detailedInfo="The Forge is DroneForge's central hub for all things AI and automation. Browse, download and share ready-to-deploy drone missions, obstacle avoidance models, object detection algorithms, and more. Join a growing community of drone enthusiasts and professionals to enhance your drone's capabilities."
            forgeLink="/the-forge"
            showExplore={false}
          />
        </div>
      </div>
      
      {/* Waitlist modal */}
      {showWaitlist && (
        <motion.div
          className="fixed inset-0 z-50 flex items-center justify-center p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <motion.div 
            className="absolute inset-0 bg-black/90 backdrop-blur-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            onClick={() => setShowWaitlist(false)}
          />
          <motion.div 
            className="relative z-10"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ 
              duration: 0.4, 
              ease: [0.22, 1, 0.36, 1]
            }}
          >
            <WaitlistForm onSuccess={() => setShowWaitlist(false)} />
          </motion.div>
        </motion.div>
      )}
    </section>
  );
};

interface ProductCardProps {
  imageSrc: string;
  title: string;
  price: string;
  description?: string;
  detailedInfo?: string;
  onWaitlist?: () => void;
  forgeLink?: string;
  showExplore?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({ 
  imageSrc, 
  title, 
  price, 
  description,
  detailedInfo,
  onWaitlist,
  forgeLink,
  showExplore = true
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [showInfo, setShowInfo] = useState(false);
  const isInView = useInView(cardRef, { 
    once: true, 
    amount: 0.2,
    margin: "0px 0px -100px 0px" // Trigger animation closer to actual viewport
  });
  
  // Calculate scroll-based animations
  const { scrollYProgress } = useScroll({
    target: cardRef,
    offset: ["start end", "end start"]
  });
  
  // Transform values based on scroll position - no initial rotation
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.4], [0, 0.8, 1]);
  const y = useTransform(scrollYProgress, [0, 0.2, 0.4], [40, 10, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.2, 0.4], [0.95, 0.98, 1]);
  
  // Function to scroll to the UseCaseShowcaseSection with a smoother animation
  const scrollToUseCases = () => {
    const useCaseSection = document.getElementById('usecase-showcase-section');
    if (useCaseSection) {
      window.scrollTo({
        top: useCaseSection.offsetTop - 50, // Offset by 50px to avoid being right at the edge
        behavior: 'smooth',
        // Use a longer duration for the scroll animation
      });
      
      // Focus the section for accessibility
      useCaseSection.setAttribute('tabindex', '-1');
      useCaseSection.focus({ preventScroll: true });
    }
  };
  
  // Toggle info display - with mouse event
  const handleInfoButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
  };
  
  // Toggle info display - without params
  const handleBackButtonClick = () => {
    setShowInfo(false);
  };
  
  return (
    <motion.div 
      ref={cardRef}
      style={{
        opacity,
        y,
        scale,
        transformPerspective: 1200,
      }}
      initial={{ rotateX: 0, rotateY: 0 }}
      whileHover={{
        rotateX: -5,
        rotateY: 2,
        transition: { duration: 0.3 }
      }}
      className="relative rounded-3xl overflow-hidden border-0 bg-black h-[550px] md:h-[600px] group"
    >
      {/* Info button */}
      <button 
        onClick={handleInfoButtonClick}
        className="absolute top-4 right-4 w-10 h-10 rounded-full bg-white/90 flex items-center justify-center transition-transform hover:scale-110 z-30"
        aria-label="View details"
      >
        <FaInfoCircle className="w-5 h-5 text-gray-700" />
      </button>
      
      {/* Full-size image as background */}
      <motion.div 
        className={`absolute inset-0 z-0 overflow-hidden transition-all duration-300 ${showInfo ? 'filter blur-md' : ''}`}
      >
        <motion.img
          src={imageSrc}
          alt={title}
          className={`w-full h-full object-cover object-center transition-transform duration-700 ${showInfo ? 'scale-[1.05]' : 'group-hover:scale-[1.02]'}`}
          style={{
            scale: useTransform(scrollYProgress, [0, 0.4], [1.1, 1]),
          }}
        />
        
        {/* Lighter gradient overlay for better image visibility */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
        
        {/* Hover glow effect */}
        <div className="absolute inset-0 bg-gradient-to-tr from-[#00F135]/0 to-white/0 group-hover:from-[#00F135]/10 group-hover:to-white/5 transition-all duration-700 ease-out" />
      </motion.div>
      
      {/* Detailed info overlay */}
      {showInfo && (
        <motion.div 
          className="absolute inset-0 z-20 bg-black/70 backdrop-blur-sm p-8 flex flex-col justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.h2 
            className="text-3xl font-horizon text-[#00F135] mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1, duration: 0.4 }}
          >
            {title}
          </motion.h2>
          
          <motion.p 
            className="text-white text-lg leading-relaxed mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.4 }}
          >
            {detailedInfo || description}
          </motion.p>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.4 }}
          >
            <DroneforgeButton 
              variant="primary" 
              size="md"
              onClick={handleBackButtonClick}
            >
              Back to Card
            </DroneforgeButton>
          </motion.div>
        </motion.div>
      )}
      
      {/* Animated shine effect across the entire card */}
      <motion.div 
        className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent z-10"
        style={{
          opacity: useTransform(scrollYProgress, [0, 0.2, 0.4], [0, 0.5, 0]),
          x: useTransform(scrollYProgress, [0, 0.4], [-200, 200]),
          filter: 'blur(5px)',
        }}
      />
      
      {/* Hover shine effect - full-card shimmer */}
      <div className="absolute inset-0 opacity-0 group-hover:opacity-100 z-10 transition-opacity duration-700 ease-out overflow-hidden">
        <div className="absolute top-0 left-0 h-[200%] w-[80%] bg-gradient-to-r from-transparent via-white/20 to-transparent skew-x-[30deg] transform -translate-x-full group-hover:translate-x-[200%] transition-transform duration-1500 ease-out" />
      </div>
      
      {/* Content area - positioned at very bottom with full width */}
      <div className={`absolute bottom-0 left-0 right-0 z-20 ${showInfo ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}>
        {/* Content with semi-transparent blur effect behind text - full width */}
        <div className="px-0">
          <div className="relative">
            {/* White-toned transparent blur effect - full width */}
            <div className="absolute inset-0 bg-white/15 backdrop-blur-[2px] rounded-t-xl rounded-b-none transition-all duration-300 group-hover:bg-white/25" />
            
            <div className="relative z-10 py-6 px-8 min-h-[160px]">
              <div className="flex flex-wrap md:flex-nowrap items-center justify-between gap-4">
                <div className="flex-1">
                  {isInView && (
                    <motion.div
                      initial={false}
                      animate="visible"
                      variants={{
                        hidden: { opacity: 0 },
                        visible: { opacity: 1, transition: { staggerChildren: 0.15 } }
                      }}
                    >
                      <motion.h2 
                        className="text-2xl md:text-3xl font-horizon tracking-tight text-white mb-0.5 group-hover:text-[#00F135] transition-colors duration-300 min-h-[36px]"
                        variants={{
                          hidden: { opacity: 0, y: 20 },
                          visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } }
                        }}
                      >
                        {title}
                      </motion.h2>
                      
                      <motion.p 
                        className="text-md md:text-lg text-[#00F135] font-light mb-0.5 tracking-wider min-h-[28px]"
                        variants={{
                          hidden: { opacity: 0, y: 15 },
                          visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut", delay: 0.1 } }
                        }}
                      >
                        {price}
                      </motion.p>
                      
                      {description && (
                        <motion.p 
                          className="text-white/90 text-xs md:text-sm leading-relaxed max-w-md font-light mb-0 min-h-[40px]"
                          variants={{
                            hidden: { opacity: 0, y: 10 },
                            visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut", delay: 0.2 } }
                          }}
                        >
                          {description}
                        </motion.p>
                      )}
                    </motion.div>
                  )}
                </div>
                
                {isInView && (
                  <motion.div 
                    className="flex flex-col gap-1 md:self-center pb-1 min-h-[80px] justify-center"
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ 
                      duration: 0.5, 
                      ease: "easeOut", 
                      delay: 0.3
                    }}
                  >
                    {showExplore && (
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.98 }}
                        transition={{ duration: 0.2 }}
                        className="origin-center transform transition-all duration-300 group-hover:translate-y-[-2px]"
                      >
                        <DroneforgeButton 
                          variant="primary" 
                          size="sm"
                          onClick={title === "Nimbus" ? scrollToUseCases : undefined}
                        >
                          Explore
                        </DroneforgeButton>
                      </motion.div>
                    )}
                    
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.98 }}
                      transition={{ duration: 0.2 }}
                      className="origin-center transform transition-all duration-300 group-hover:translate-y-[-2px]"
                    >
                      <DroneforgeButton 
                        variant="accent" 
                        size="sm"
                        onClick={title === "Nimbus" && onWaitlist ? onWaitlist : undefined}
                        href={forgeLink}
                      >
                        {title === "Nimbus" ? "Join Waitlist" : "VISIT THE FORGE"}
                      </DroneforgeButton>
                    </motion.div>
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}; 