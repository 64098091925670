import React, { useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import { DroneforgeButton } from '../DroneforgeButton.tsx';
import WaitlistForm from '../WaitlistForm.tsx';

export const ProductDescriptionSection: React.FC = () => {
  const textRef = useRef<HTMLHeadingElement>(null);
  const isTextInView = useInView(textRef, { once: true, amount: 0.1 });
  const [showWaitlist, setShowWaitlist] = useState(false);
  
  // Shortened text with key words structure for emphasis
  const textContent = [
    { text: "Give your drone a", emphasis: false },
    { text: "mind", emphasis: true },
    { text: "of its own — a", emphasis: false },
    { text: "partner", emphasis: true },
    { text: "that", emphasis: false },
    { text: "sees", emphasis: true },
    { text: ",", emphasis: false },
    { text: "decides", emphasis: true },
    { text: ", and", emphasis: false },
    { text: "acts", emphasis: true },
    { text: "with you.", emphasis: false },
  ];

  return (
    <section className="w-full py-32 bg-white relative overflow-hidden">
      {/* Subtle background pattern (same as BufferSection) */}
      <div className="absolute inset-0 opacity-[0.06]">
        <div 
          className="h-full w-full" 
          style={{
            backgroundImage: `radial-gradient(circle, rgba(0, 241, 53, 0.5) 1px, transparent 1px)`,
            backgroundSize: '40px 40px',
          }}
        />
      </div>
      
      {/* Very subtle glow effects */}
      <div className="absolute top-1/3 left-1/4 w-96 h-96 rounded-full bg-[#00F135]/5 filter blur-[120px] opacity-[0.15]" />
      <div className="absolute bottom-1/3 right-1/4 w-80 h-80 rounded-full bg-[#00F135]/5 filter blur-[100px] opacity-[0.1]" />
      
      <div className="container mx-auto px-6 text-center relative z-10">
        <div className="max-w-4xl mx-auto">
          {/* Animated heading with sequential fade in */}
          <h2 
            ref={textRef}
            className="text-3xl md:text-4xl lg:text-5xl font-medium text-black mb-6 font-montserrat leading-relaxed"
          >
            {textContent.map((segment, i) => (
              <motion.span
                key={i}
                initial={{ opacity: 0, y: 20 }}
                animate={isTextInView ? 
                  { 
                    opacity: 1, 
                    y: 0,
                    transition: { 
                      duration: 0.5, 
                      delay: i * 0.15,
                      ease: [0.2, 0.65, 0.3, 0.9] 
                    }
                  } : 
                  { opacity: 0, y: 20 }
                }
                className={`inline-block mr-2 ${
                  segment.emphasis ? 
                  'text-[#00F135] font-semibold relative' : 
                  'text-black'
                }`}
              >
                {segment.text}
                {segment.emphasis && (
                  <motion.span
                    className="absolute bottom-0 left-0 right-0 h-[3px] bg-[#00F135]/30"
                    initial={{ scaleX: 0 }}
                    animate={isTextInView ? 
                      { 
                        scaleX: 1,
                        transition: { 
                          duration: 0.6, 
                          delay: i * 0.15 + 0.3,
                          ease: [0.16, 1, 0.3, 1] 
                        }
                      } : 
                      { scaleX: 0 }
                    }
                    style={{ originX: 0 }}
                  />
                )}
              </motion.span>
            ))}
          </h2>
          
          {/* Tagline */}
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={isTextInView ? 
              { 
                opacity: 1, 
                y: 0,
                transition: { 
                  duration: 0.6, 
                  delay: textContent.length * 0.15 + 0.2,
                  ease: [0.2, 0.65, 0.3, 0.9] 
                }
              } : 
              { opacity: 0, y: 20 }
            }
            className="text-xl md:text-2xl text-gray-700 font-light mb-14"
          >
            This is autonomy. Reimagined.
          </motion.p>
          
          {/* Custom Button with proper styling and animations */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={isTextInView ? 
              { 
                opacity: 1, 
                y: 0,
                transition: { 
                  duration: 0.6, 
                  delay: textContent.length * 0.15 + 0.2,
                  ease: [0.2, 0.65, 0.3, 0.9] 
                }
              } : 
              { opacity: 0, y: 20 }
            }
            className="inline-block relative z-10"
          >
            {/* Soft glow effect underneath */}
            <div className="absolute -inset-1 bg-[#00F135]/20 blur-lg rounded-full opacity-70"></div>
            
            {/* Custom button instead of DroneforgeButton to ensure proper styling */}
            <motion.button
              className="relative bg-[#00F135] hover:bg-[#00F135]/90 text-black font-semibold rounded-full px-12 py-5 text-xl tracking-wide transition-all duration-300 overflow-hidden"
              whileHover={{ 
                scale: 1.05,
                boxShadow: "0px 0px 25px rgba(0, 241, 53, 0.5)",
                transition: { duration: 0.2 }
              }}
              whileTap={{ 
                scale: 0.97,
                transition: { duration: 0.1 }
              }}
              onClick={() => setShowWaitlist(true)}
            >
              Get Updated
              
              {/* Shine effect */}
              <motion.div 
                className="absolute inset-0 bg-gradient-to-r from-transparent via-white/40 to-transparent skew-x-12"
                animate={{ 
                  x: [-200, 200],
                  opacity: [0, 0.7, 0],
                }}
                transition={{ 
                  repeat: Infinity,
                  repeatDelay: 2,
                  duration: 1,
                  ease: "easeInOut"
                }}
              />
            </motion.button>
          </motion.div>
        </div>
      </div>

      {/* Waitlist modal */}
      {showWaitlist && (
        <motion.div
          className="fixed inset-0 z-50 flex items-center justify-center p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <motion.div 
            className="absolute inset-0 bg-black/90 backdrop-blur-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            onClick={() => setShowWaitlist(false)}
          />
          <motion.div 
            className="relative z-10"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ 
              duration: 0.4, 
              ease: [0.22, 1, 0.36, 1]
            }}
          >
            <WaitlistForm onSuccess={() => setShowWaitlist(false)} />
          </motion.div>
        </motion.div>
      )}
    </section>
  );
};

export default ProductDescriptionSection; 