import React from 'react';

export const TestBackground: React.FC = () => {
  return (
    <div className="relative w-screen h-screen">
      {/* Direct background image with no filters or overlays */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: "url('/BACKGROUND.jpg')",
        }}
      >
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-white text-4xl font-bold bg-black/50 p-4 rounded">
            Testing Background Image
          </h1>
        </div>
      </div>
    </div>
  );
}; 