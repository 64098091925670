"use client"
import React, { useState, useEffect } from 'react';
import { HeroSection2 } from './sections/HeroSection2.tsx';
import { BufferSection } from './sections/BufferSection.tsx';
import { Navbar } from './Navbar.tsx';
import './styles/globals.css';
import Interface from './sections/Interface.tsx';
import { ProductDescriptionSection } from './sections/ProductDescriptionSection.tsx';
import UseCaseShowcaseSection from './sections/UseCaseShowcaseSection.tsx';

const Landing_Bryce: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showContent, setShowContent] = useState(false);
  
  // Check for mobile device on client-side
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  
  // Fade in content with a short delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 800); // Reduced delay for better UX

    return () => clearTimeout(timer);
  }, []);

  // Set body to white background
  useEffect(() => {
    // Ensure the body has natural scrolling behavior
    document.body.style.overflow = 'visible';
    document.body.style.height = 'auto';
    
    // Set background color to white
    document.body.style.backgroundColor = 'white';
    
    return () => {
      // Reset styles when component unmounts
      document.body.style.overflow = '';
      document.body.style.height = '';
    };
  }, []);

  // Mobile or desktop - same layout with white background
  return (
    <div className="relative w-full bg-white">
      {/* Navbar */}
      <Navbar show={showContent} />
      
      {/* Main content */}
      <main className="relative w-full">
        {/* Hero section */}
        <section className="relative w-full">
          <HeroSection2 />
        </section>
        
        {/* Other sections */}
        <BufferSection />
        <ProductDescriptionSection />
        <Interface />
        <UseCaseShowcaseSection />
      </main>
    </div>
  );
};

export default Landing_Bryce;