'use client';

import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { DroneforgeButton } from '../DroneforgeButton.tsx';

// Define use case data
const useCases = [
  {
    id: 'plug-and-play',
    title: 'Quick Setup',
    subtitle: 'Plug-and-Play',
    description: 'Connect Nimbus. Deploy AI. Fly smart.',
    features: [
      'Instant Setup',
      'No Coding',
      'Ready to Fly'
    ],
    imageSrc: '/webp_converted/plug_in.webp',
  },
  {
    id: 'one-controller',
    title: 'Works With Any Drone Type',
    subtitle: 'One Controller, Any Drone',
    description: 'Use DroneForge Nimbus with virtually any drone in your collection - from 1-inch micro drones to cinewhoops, freestyle quads, and professional platforms. One software solution for your entire fleet.',
    features: [
      'Compatible With All Drone Types',
      'Works With Cinewhoops, Freestyle & More',
      'No Hardware Modifications Needed'
    ],
    imageSrc: '/webp_converted/segmentation_cinematic.webp',
  },
  {
    id: 'real-time-roi',
    title: 'Instant Data Analysis',
    subtitle: 'Real-time Insights',
    description: 'Gain actionable insights immediately with DroneForge Nimbus real-time data processing and analysis. Make faster, data-driven decisions without lengthy post-processing.',
    features: [
      'Live Data Processing',
      'Immediate Visualization',
      'Actionable Intelligence'
    ],
    imageSrc: '/webp_converted/bounding_box_cinematic.webp',
  },
  {
    id: 'the-forge',
    title: 'Community Marketplace',
    subtitle: 'The Forge',
    description: 'Access a centralized platform where users share models, missions, and automation solutions. Download ready-made configurations or contribute your own to build a thriving drone community.',
    features: [
      'Shared Mission Repository',
      'Download & Use Community Models',
      'Collaborate With Other Users'
    ],
    imageSrc: '/webp_converted/obstacle_avoidance_cinematic.webp',
  },
];

const UseCaseShowcaseSection: React.FC = () => {
  const [activeTab, setActiveTab] = useState('plug-and-play');
  const tabsContainerRef = useRef<HTMLDivElement>(null);

  // Find active use case
  const activeUseCase = useCases.find(useCase => useCase.id === activeTab) || useCases[0];
  
  // Scroll to active tab on tab change
  useEffect(() => {
    if (!tabsContainerRef.current) return;
    
    const tabsContainer = tabsContainerRef.current;
    const activeTabElement = tabsContainer.querySelector(`[data-tab-id="${activeTab}"]`);
    
    if (activeTabElement) {
      const containerWidth = tabsContainer.offsetWidth;
      const tabWidth = activeTabElement.clientWidth;
      const tabLeft = (activeTabElement as HTMLElement).offsetLeft;
      
      // Calculate center position
      const scrollPosition = tabLeft - (containerWidth / 2) + (tabWidth / 2);
      
      tabsContainer.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    }
  }, [activeTab]);

  return (
    <section id="usecase-showcase-section" className="w-full pt-16 sm:pt-24 pb-12 sm:pb-16 bg-white relative overflow-hidden">
      {/* Subtle background pattern */}
      <div className="absolute inset-0 opacity-[0.06]">
        <div 
          className="h-full w-full" 
          style={{
            backgroundImage: `radial-gradient(circle, rgba(0, 241, 53, 0.5) 1px, transparent 1px)`,
            backgroundSize: '40px 40px',
          }}
        />
      </div>
      
      {/* Very subtle glow effects */}
      <div className="absolute top-1/4 left-1/4 w-96 h-96 rounded-full bg-[#00F135]/5 filter blur-[120px] opacity-[0.15]" />
      <div className="absolute bottom-1/4 right-1/4 w-80 h-80 rounded-full bg-[#00F135]/5 filter blur-[100px] opacity-[0.1]" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        {/* Header section */}
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6 sm:gap-12 mb-10 sm:mb-16 items-center">
          <div className="md:col-span-7">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-gray-800 leading-tight mb-6">
              <span className="font-normal">From vision to</span> <span className="font-bold text-[#00F135]">reality</span><span className="font-normal">, powering the</span> <span className="font-bold text-[#00F135]">next generation</span>
            </h3>
          </div>
          
          <div className="md:col-span-5">
            <p className="text-base sm:text-lg text-gray-700 leading-relaxed">
              Experience the seamless fusion of cutting-edge AI and drone technology. 
              Whether you're surveying construction sites, monitoring crops, or innovating 
              new applications, Nimbus adapts to your unique needs.
            </p>
          </div>
        </div>
        
        {/* Tabs section - optimized mobile scrolling */}
        <div className="mb-8 overflow-hidden">
          <div 
            ref={tabsContainerRef}
            className="overflow-x-auto scrollbar-hide pb-3 -mx-4 px-4 touch-pan-x"
            style={{ WebkitOverflowScrolling: 'touch' }}
          >
            <nav className="flex items-center border-b border-gray-200 min-w-max">
              {useCases.map((useCase) => (
                <button
                  key={useCase.id}
                  data-tab-id={useCase.id}
                  onClick={() => setActiveTab(useCase.id)}
                  className={`
                    px-4 py-3 mx-1 text-base sm:text-lg font-medium transition-all whitespace-nowrap flex-shrink-0
                    ${activeTab === useCase.id ? 
                      'text-[#00F135] border-b-2 border-[#00F135] -mb-[1px]' : 
                      'text-gray-500 hover:text-gray-700 border-b-2 border-transparent'}
                  `}
                >
                  {useCase.subtitle}
                </button>
              ))}
            </nav>
          </div>
          
          {/* Mobile tab indicators */}
          <div className="flex justify-center mt-2 space-x-1 sm:hidden">
            {useCases.map((useCase) => (
              <div
                key={useCase.id}
                className={`h-1.5 rounded-full transition-all duration-300 ${
                  activeTab === useCase.id ? 'bg-[#00F135] w-6' : 'bg-gray-300 w-1.5'
                }`}
                onClick={() => setActiveTab(useCase.id)}
              />
            ))}
          </div>
        </div>
        
        {/* Content section */}
        <motion.div 
          key={activeTab}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          className="w-full"
        >
          {activeTab === 'plug-and-play' ? (
            // For plug-and-play, just show the centered image
            <div className="w-full flex justify-center">
              <div className="w-full max-w-2xl rounded-2xl overflow-hidden shadow-lg aspect-video">
                <img 
                  src="/webp_converted/plug_in.webp" 
                  alt="Plug and Play"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          ) : activeTab === 'one-controller' ? (
            // For one-controller, responsive layout for the three drone images
            <div className="w-full flex justify-center">
              <div className="w-full relative">
                {/* Mobile layout - stack images vertically */}
                <div className="block sm:hidden space-y-4">
                  <div className="w-full rounded-xl overflow-hidden shadow-lg h-52">
                    <img 
                      src="/webp_converted/drone_agriculture.webp" 
                      alt="Agriculture Drone"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  
                  <div className="w-full rounded-xl overflow-hidden shadow-lg h-52">
                    <img 
                      src="/webp_converted/drone_construction.webp" 
                      alt="Construction Drone"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  
                  <div className="w-full rounded-xl overflow-hidden shadow-lg h-52">
                    <img 
                      src="/webp_converted/drone_warehouse.webp" 
                      alt="Warehouse Drone"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
                
                {/* Desktop layout - asymmetric arrangement */}
                <div className="hidden sm:block relative h-[500px]">
                  {/* Drone Agriculture - positioned top left */}
                  <div className="absolute top-0 left-0 w-80 md:w-96 h-64 md:h-72 rounded-xl overflow-hidden shadow-lg transform -rotate-1">
                    <img 
                      src="/webp_converted/drone_agriculture.webp" 
                      alt="Agriculture Drone"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  
                  {/* Drone Construction - positioned top right */}
                  <div className="absolute top-0 right-0 w-80 md:w-96 h-64 md:h-72 rounded-xl overflow-hidden shadow-lg transform rotate-1 z-10">
                    <img 
                      src="/webp_converted/drone_construction.webp" 
                      alt="Construction Drone"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  
                  {/* Drone Warehouse - positioned bottom center */}
                  <div className="absolute bottom-0 left-[25%] w-80 md:w-96 h-64 md:h-72 rounded-xl overflow-hidden shadow-lg transform rotate-[0.5deg] z-20">
                    <img 
                      src="/webp_converted/drone_warehouse.webp" 
                      alt="Warehouse Drone"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : activeTab === 'real-time-roi' ? (
            // For real-time-roi, responsive layout for analysis images
            <div className="w-full">
              {/* Mobile layout - vertical stack */}
              <div className="block sm:hidden space-y-6">
                <div className="w-full">
                  <div className="rounded-xl overflow-hidden shadow-lg mb-3">
                    <img 
                      src="/webp_converted/analysis_warehouse.webp" 
                      alt="Warehouse Analysis"
                      className="w-full object-cover"
                    />
                  </div>
                  <p className="text-center font-bold text-gray-800 text-base">WAREHOUSE LOGISTICS</p>
                </div>
                
                <div className="w-full">
                  <div className="rounded-xl overflow-hidden shadow-lg mb-3">
                    <img 
                      src="/webp_converted/analysis_agriculture.webp" 
                      alt="Agriculture Analysis"
                      className="w-full object-cover"
                    />
                  </div>
                  <p className="text-center font-bold text-gray-800 text-base">DISEASE MONITORING</p>
                </div>
                
                <div className="w-full">
                  <div className="rounded-xl overflow-hidden shadow-lg mb-3">
                    <img 
                      src="/webp_converted/analysis_construction.webp" 
                      alt="Construction Analysis"
                      className="w-full object-cover"
                    />
                  </div>
                  <p className="text-center font-bold text-gray-800 text-base">CONSTRUCTION 3D MODELS</p>
                </div>
              </div>
              
              {/* Desktop layout - positioned images */}
              <div className="hidden sm:block relative h-[680px]">
                {/* Warehouse Analysis - top left */}
                <div className="absolute top-10 left-[3%] w-[300px] md:w-[350px]">
                  <div className="rounded-xl overflow-hidden shadow-lg mb-3">
                    <img 
                      src="/webp_converted/analysis_warehouse.webp" 
                      alt="Warehouse Analysis"
                      className="w-full object-cover"
                    />
                  </div>
                  <p className="text-center font-bold text-gray-800 text-lg">WAREHOUSE LOGISTICS</p>
                </div>
                
                {/* Agriculture Analysis - top right */}
                <div className="absolute top-10 right-[3%] w-[300px] md:w-[350px]">
                  <div className="rounded-xl overflow-hidden shadow-lg mb-3">
                    <img 
                      src="/webp_converted/analysis_agriculture.webp" 
                      alt="Agriculture Analysis"
                      className="w-full object-cover"
                    />
                  </div>
                  <p className="text-center font-bold text-gray-800 text-lg">DISEASE MONITORING</p>
                </div>
                
                {/* Construction Analysis - bottom center */}
                <div className="absolute bottom-30 left-1/2 transform -translate-x-1/2 w-[300px] md:w-[350px]">
                  <div className="rounded-xl overflow-hidden shadow-lg mb-3">
                    <img 
                      src="/webp_converted/analysis_construction.webp" 
                      alt="Construction Analysis"
                      className="w-full object-cover"
                    />
                  </div>
                  <p className="text-center font-bold text-gray-800 text-lg">CONSTRUCTION 3D MODELS</p>
                </div>
              </div>
            </div>
          ) : activeTab === 'the-forge' ? (
            // For the-forge, responsive layout for text and button
            <div className="w-full py-10 sm:py-20">
              <div className="flex flex-col items-center sm:items-start gap-8">
                <h3 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800 text-center sm:text-left">
                  DOWNLOAD AI MODELS, MISSIONS, and MORE
                </h3>
                
                <div className="flex justify-center sm:justify-start w-full">
                  <DroneforgeButton 
                    variant="accent" 
                    size="lg"
                    href="/the-forge"
                    className="rounded-full whitespace-nowrap"
                  >
                    <span className="flex items-center">
                      Visit The Forge
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </span>
                  </DroneforgeButton>
                </div>
              </div>
            </div>
          ) : (
            // For all other tabs, improved responsive layout
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-start">
              {/* Text content */}
              <div>
                <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2">{activeUseCase.title}</h3>
                <p className="text-base sm:text-lg text-gray-700 mb-6">{activeUseCase.description}</p>
                
                {/* Features list */}
                <div className="mb-6">
                  <h4 className="text-sm font-semibold text-gray-500 uppercase tracking-wider mb-3">Key Features</h4>
                  <ul className="space-y-2">
                    {activeUseCase.features.map((feature, index) => (
                      <li key={index} className="flex items-start">
                        <span className="w-2 h-2 bg-[#00F135] rounded-full mr-3 mt-1.5"></span>
                        <span className="text-gray-700">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                <DroneforgeButton 
                  variant="accent" 
                  size="md"
                >
                  Learn More
                </DroneforgeButton>
              </div>
              
              {/* Visual content */}
              <div className="bg-gray-100 rounded-2xl overflow-hidden shadow-lg aspect-video relative mt-4 md:mt-0">
                <div className="absolute inset-0 bg-gradient-to-br from-black/10 to-black/0" />
                <img 
                  src={activeUseCase.imageSrc || "/webp_converted/nimbus.webp"} 
                  alt={activeUseCase.title}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          )}
        </motion.div>
      </div>
      
      {/* Custom styles */}
      <style dangerouslySetInnerHTML={{__html: `
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        
        /* Ensure smooth scrolling for iOS */
        @supports (-webkit-touch-callout: none) {
          .scrollbar-hide {
            -webkit-overflow-scrolling: touch;
          }
          .touch-pan-x {
            touch-action: pan-x;
          }
        }
      `}} />
    </section>
  );
};

export default UseCaseShowcaseSection; 