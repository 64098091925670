import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { DroneforgeButton } from '../DroneforgeButton.tsx';
import { HiOutlineArrowUpRight, HiOutlineArrowLeft, HiOutlineHome, HiOutlineInformationCircle } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';

const buildSteps = [
  {
    id: 'frame',
    title: 'Frame',
    description: 'The foundation of your drone',
    infoCards: [
      {
        title: 'Size Matters',
        description: 'The frame size determines how big your drone will be. Smaller frames are perfect for indoor flying, while larger frames are better for outdoor adventures.'
      },
      {
        title: 'Durability',
        description: 'Most frames are made from carbon fiber, offering the perfect balance of strength and weight. The thickness of the arms affects both durability and weight.'
      },
      {
        title: 'Compatibility',
        description: 'Your frame choice affects which components you can use. Larger frames support bigger motors and propellers, while smaller frames need compact components.'
      }
    ],
    options: [
      {
        name: '3-inch Micro',
        image: '/byod/3inch_premade.jpg',
        description: 'Perfect for indoor and tight spaces',
        specs: {
          size: '3 inch',
          wheelbase: '150mm',
          weight: '70g',
          motorMounts: '1106-1404',
          fcMount: '20x20mm',
          price: 49.99
        }
      },
      {
        name: '5-inch Freestyle',
        image: '/byod/5inch_premade.jpg',
        description: 'The sweet spot for learning FPV',
        specs: {
          size: '5 inch',
          wheelbase: '220mm',
          weight: '110g',
          motorMounts: '2204-2306',
          fcMount: '30.5x30.5mm',
          price: 69.99
        }
      },
      {
        name: '7-inch Long Range',
        image: '/byod/7inch_premade.png',
        description: 'Built for distance and endurance',
        specs: {
          size: '7 inch',
          wheelbase: '300mm',
          weight: '150g',
          motorMounts: '2507-2808',
          fcMount: '30.5x30.5mm',
          price: 89.99
        }
      }
    ],
    customFields: [
      {
        id: 'name',
        label: 'Frame Name',
        placeholder: 'Example: TBS Source One V5',
        type: 'text'
      },
      {
        id: 'wheelbase',
        label: 'Wheelbase',
        type: 'select',
        options: [
          { value: '150', label: '150mm' },
          { value: '180', label: '180mm' },
          { value: '220', label: '220mm' },
          { value: '250', label: '250mm' },
          { value: '280', label: '280mm' }, 
          { value: '300', label: '300mm' },
          { value: '350', label: '350mm' },
          { value: 'custom', label: '+ Custom Wheelbase' }
        ]
      },
      {
        id: 'motorMounts',
        label: 'Motor Mount Size',
        type: 'select',
        options: [
          { value: '16x16', label: '16x16mm' },
          { value: '19x19', label: '19x19mm' },
          { value: '25x25', label: '25x25mm' },
          { value: '30.5x30.5', label: '30.5x30.5mm' },
          { value: 'custom', label: '+ Custom Mount Size' }
        ]
      },
      {
        id: 'fcMount',
        label: 'FC Mount Pattern',
        type: 'select',
        options: [
          { value: '20x20', label: '20x20mm' },
          { value: '25.5x25.5', label: '25.5x25.5mm' },
          { value: '30.5x30.5', label: '30.5x30.5mm' },
          { value: '35x35', label: '35x35mm' },
          { value: 'custom', label: '+ Custom FC Mount' }
        ]
      },
      {
        id: 'weight',
        label: 'Frame Weight',
        placeholder: 'Example: 110g',
        type: 'text'
      },
      {
        id: 'url',
        label: 'Product URL (optional)',
        placeholder: 'Link to where you found this component',
        type: 'url'
      },
      {
        id: 'price',
        label: 'Price',
        placeholder: 'Enter price in USD',
        type: 'number'
      }
    ]
  },
  {
    id: 'motors',
    title: 'Motors',
    description: 'Power and performance',
    infoCards: [
      {
        title: 'KV Rating',
        description: 'KV determines how fast your motors spin per volt. Lower KV is better for larger props and efficiency, higher KV for agility.'
      },
      {
        title: 'Size & Power',
        description: 'Motor size affects power output and efficiency. Larger motors generally provide more power but add weight.'
      },
      {
        title: 'Quality Matters',
        description: 'Better quality motors offer improved efficiency, durability, and smoother operation.'
      }
    ],
    options: [
      {
        name: 'Xilo Stealth 1404 4500KV',
        image: '/byod/3inch_motor.webp',
        description: 'Perfect for 3-inch builds',
        specs: {
          size: '1404',
          kv: '4500KV',
          weight: '9.2g',
          maxThrust: '351g',
          maxCurrent: '20A',
          propSize: '3-inch',
          motorMount: '12x12mm (M2)',
          statorHeight: '4mm',
          shaftDiameter: '1.5mm',
          maxPower: '225W',
          voltage: '15V (4S)',
          price: 19.99,
          name: 'Xilo Stealth 1404 4500KV'
        }
      },
      {
        name: 'XILO Stealth 2207 Motor - 1800KV',
        image: '/byod/5inch_motor.jpg',
        description: 'Stealth design for 5-inch builds',
        specs: {
          size: '2207',
          kv: '1800KV',
          weight: '31g',
          statorDiameter: '22mm',
          statorHeight: '7mm',
          configuration: '9N12P',
          motorConnection: 'Bare Wire',
          wireLength: '180mm',
          bearing: 'Stainless Steel (9x4x4mm)',
          maxThrust: '1250g',
          maxCurrent: '35A',
          price: 24.99,
          name: 'XILO Stealth 2207 Motor - 1800KV'
        }
      },
      {
        name: 'Lumenier 2807 JohnnyFPV V3 Pro Cinematic Motor',
        image: '/byod/7inch_motor.jpg',
        description: 'Premium cinematic performance',
        specs: {
          size: '2807',
          kv: '1350KV',
          weight: '55.8g',
          shaftDiameter: '5mm-M5',
          bellMaterial: '6061-T6 Aluminum',
          wireLength: '110mm',
          wireGauge: '20AWG',
          dimensions: '32x13mm',
          configuration: '12N14P',
          maxThrust: '1800g',
          maxCurrent: '40A',
          price: 34.99,
          name: 'Lumenier 2807 JohnnyFPV V3 Pro Cinematic Motor'
        }
      }
    ],
    customFields: [
      {
        id: 'name',
        label: 'Motor Name',
        placeholder: 'Example: T-Motor F60 Pro V',
        type: 'text'
      },
      {
        id: 'size',
        label: 'Motor Size',
        type: 'select',
        options: [
          { value: '1404', label: '1404' },
          { value: '2203', label: '2203' },
          { value: '2204', label: '2204' },
          { value: '2205', label: '2205' },
          { value: '2206', label: '2206' },
          { value: '2207', label: '2207' },
          { value: '2306', label: '2306' },
          { value: '2307', label: '2307' },
          { value: '2405', label: '2405' },
          { value: '2406', label: '2406' },
          { value: '2807', label: '2807' },
          { value: 'custom', label: '+ Custom Size' }
        ]
      },
      {
        id: 'kv',
        label: 'KV Rating',
        type: 'select',
        options: [
          { value: '1350', label: '1350KV' },
          { value: '1700', label: '1700KV' },
          { value: '1750', label: '1750KV' },
          { value: '1800', label: '1800KV' },
          { value: '1850', label: '1850KV' },
          { value: '1900', label: '1900KV' },
          { value: '2000', label: '2000KV' },
          { value: '2200', label: '2200KV' },
          { value: '2400', label: '2400KV' },
          { value: '2600', label: '2600KV' },
          { value: '4500', label: '4500KV' },
          { value: 'custom', label: '+ Custom KV' }
        ]
      },
      {
        id: 'weight',
        label: 'Weight',
        placeholder: 'Example: 32g',
        type: 'text'
      },
      {
        id: 'maxThrust',
        label: 'Maximum Thrust',
        type: 'select',
        options: [
          { value: '351', label: '351g' },
          { value: '500', label: '500g' },
          { value: '750', label: '750g' },
          { value: '1000', label: '1000g' },
          { value: '1200', label: '1200g' },
          { value: '1500', label: '1500g' },
          { value: '1800', label: '1800g' },
          { value: '2000', label: '2000g' },
          { value: 'custom', label: '+ Custom Thrust' }
        ]
      },
      {
        id: 'maxCurrent',
        label: 'Maximum Current',
        type: 'select',
        options: [
          { value: '15', label: '15A' },
          { value: '20', label: '20A' },
          { value: '25', label: '25A' },
          { value: '30', label: '30A' },
          { value: '35', label: '35A' },
          { value: '40', label: '40A' },
          { value: '45', label: '45A' },
          { value: 'custom', label: '+ Custom Current' }
        ]
      },
      {
        id: 'url',
        label: 'Product URL (optional)',
        placeholder: 'Link to where you found this component',
        type: 'url'
      },
      {
        id: 'price',
        label: 'Price',
        placeholder: 'Enter price in USD',
        type: 'number'
      }
    ]
  },
  {
    id: 'esc',
    title: 'ESCs',
    description: 'Speed control and power delivery',
    infoCards: [
      {
        title: 'Current Rating',
        description: 'Choose ESCs rated above your motor\'s maximum current draw for safety and reliability.'
      },
      {
        title: 'Protocol Support',
        description: 'Modern ESCs support various protocols like DShot and Multishot for better performance.'
      },
      {
        title: 'BLHeli Support',
        description: 'BLHeli_32 offers advanced features and better performance than older firmware.'
      }
    ],
    options: [
      {
        name: 'Lumenier LUX HD AIO - G4 FC + 35A AM32 3-6S ESC',
        image: '/byod/3inch_fc.jpg',
        description: 'All-in-One Flight Controller & ESC',
        specs: {
          processor: 'STM32G473 / 170Mhz',
          gyro: 'ICM42688',
          weight: '10.3g',
          voltage: '3-6S (8-32V)',
          bec: '2x BEC, 5V and 10V, 2.5A each',
          escMcu: 'F4 AM32',
          current: '35A',
          mountingPattern: '25x25mm',
          dimensions: '36x36mm',
          currentSensor: 'Analog',
          pwmOutput: '4',
          beeper: 'Yes',
          price: 64.99
        }
      },
      {
        name: 'XILO Stax V2 F4 45A BLHeli_32 3-6S 4-in-1 ESC',
        image: '/byod/5inch_esc.jpg',
        description: '4-in-1 high performance ESC',
        specs: {
          current: '45A',
          voltage: '3-6S',
          firmware: 'BLHeli_32',
          weight: '12g',
          protocols: 'DShot1200',
          dimensions: '36 x 43mm',
          mountingHoles: '30.5 x 30.5mm',
          burst: '55A',
          processor: 'F4',
          bec: 'No',
          price: 49.99
        }
      },
      {
        name: 'Axisflying Argus Pro 55A 3-6S BLHeli_32 ESC',
        image: '/byod/7inch_esc.jpg',
        description: 'All-in-One FC & ESC with advanced features',
        specs: {
          current: '55A',
          voltage: '3-6S',
          firmware: 'BLHeli_32',
          weight: '32g',
          protocols: 'DShot1200',
          dimensions: '48.6 x 46.6 x 8.7mm',
          mountingHoles: '30.5 x 30.5mm',
          processor: 'STM32F722',
          gyro: 'BMI270',
          bec: '5V@2A and 9V@2A',
          features: 'Blackbox Flash 16MB, 6 UART ports, ESC sensor, HD/Analog support',
          price: 69.99
        }
      }
    ],
    customFields: [
      {
        id: 'name',
        label: 'ESC Name',
        placeholder: 'Example: Hobbywing 45A',
        type: 'text'
      },
      {
        id: 'current',
        label: 'Current Rating',
        type: 'select',
        options: [
          { value: '25', label: '25A' },
          { value: '30', label: '30A' },
          { value: '35', label: '35A' },
          { value: '40', label: '40A' },
          { value: '45', label: '45A' },
          { value: '50', label: '50A' },
          { value: '60', label: '60A' },
          { value: 'custom', label: '+ Custom Rating' }
        ]
      },
      {
        id: 'voltage',
        label: 'Voltage Range',
        type: 'select',
        options: [
          { value: '2-4s', label: '2-4S' },
          { value: '3-6s', label: '3-6S' },
          { value: '4-8s', label: '4-8S' },
          { value: '6-12s', label: '6-12S' },
          { value: 'custom', label: '+ Custom Voltage Range' }
        ]
      },
      {
        id: 'firmware',
        label: 'Firmware',
        type: 'select',
        options: [
          { value: 'blheli32', label: 'BLHeli_32' },
          { value: 'blheli_s', label: 'BLHeli_S' },
          { value: 'kiss', label: 'KISS' },
          { value: 'custom', label: '+ Other Firmware' }
        ]
      },
      {
        id: 'weight',
        label: 'Weight',
        placeholder: 'Example: 12g',
        type: 'text'
      },
      {
        id: 'protocols',
        label: 'Supported Protocols',
        type: 'select',
        options: [
          { value: 'dshot300', label: 'DShot300' },
          { value: 'dshot600', label: 'DShot600' },
          { value: 'dshot1200', label: 'DShot1200' },
          { value: 'multishot', label: 'Multishot' },
          { value: 'oneshot125', label: 'Oneshot125' },
          { value: 'oneshot42', label: 'Oneshot42' },
          { value: 'custom', label: '+ Custom Protocol' }
        ]
      },
      {
        id: 'url',
        label: 'Product URL (optional)',
        placeholder: 'Link to where you found this component',
        type: 'url'
      },
      {
        id: 'price',
        label: 'Price',
        placeholder: 'Enter price in USD',
        type: 'number'
      }
    ]
  },
  {
    id: 'fc',
    title: 'Flight Controller',
    description: 'The brain of your drone',
    infoCards: [
      {
        title: 'Processing Power',
        description: 'Higher processing power means faster loop times and better flight performance.'
      },
      {
        title: 'Gyro Quality',
        description: 'Better gyros provide more precise flight control and smoother operation.'
      },
      {
        title: 'Features',
        description: 'Consider built-in features like OSD, blackbox, and voltage regulators.'
      }
    ],
    options: [
      {
        name: 'Lumenier LUX HD AIO - G4 FC + 35A AM32 3-6S ESC',
        image: '/byod/3inch_fc.jpg',
        description: 'All-in-One Flight Controller & ESC',
        specs: {
          processor: 'STM32G473 / 170Mhz',
          gyro: 'ICM42688',
          weight: '10.3g',
          voltage: '3-6S (8-32V)',
          bec: '2x BEC, 5V and 10V, 2.5A each',
          escMcu: 'F4 AM32',
          current: '35A',
          mountingPattern: '25x25mm',
          dimensions: '36x36mm',
          currentSensor: 'Analog',
          pwmOutput: '4',
          beeper: 'Yes',
          price: 64.99
        }
      },
      {
        name: 'XILO Stax V3 F4 Flight Controller',
        image: '/byod/5inch_fc.jpg',
        description: 'Feature-rich F4 controller',
        specs: {
          processor: 'F405 MCU',
          gyro: 'BMI270',
          weight: '12g',
          voltage: '2-8S (8-36V)',
          bec: '5V 2A / 10V 2A',
          dimensions: '36 x 43mm',
          mountingHoles: '30.5 x 30.5mm',
          lcFilter: 'Yes',
          beeper: 'Yes',
          uarts: '6',
          i2c: '1',
          pwmOutput: '4',
          price: 54.99
        }
      },
      {
        name: 'AxisFlying Argus F7 PRO HD 3-6S 30x30 Flight',
        image: '/byod/7inch_fc.jpg',
        description: 'Premium F7 flight controller for larger builds',
        specs: {
          processor: 'STM32F7',
          gyro: 'MPU6000',
          weight: '14g',
          voltage: '3-6S',
          bec: '5V 2A / 9V 2A',
          dimensions: '36 x 36mm',
          mountingHoles: '30.5 x 30.5mm',
          uarts: '6',
          i2c: '1',
          pwmOutput: '8',
          price: 69.99
        }
      }
    ],
    customFields: [
      {
        id: 'name',
        label: 'Flight Controller',
        placeholder: 'Example: Matek H743-SLIM',
        type: 'text'
      },
      {
        id: 'processor',
        label: 'Processor',
        type: 'select',
        options: [
          { value: 'f4', label: 'STM32F4' },
          { value: 'f7', label: 'STM32F7' },
          { value: 'h7', label: 'STM32H7' },
          { value: 'custom', label: '+ Other Processor' }
        ]
      },
      {
        id: 'gyro',
        label: 'Gyro',
        type: 'select',
        options: [
          { value: 'bmi270', label: 'BMI270' },
          { value: 'mpu6000', label: 'MPU6000' },
          { value: 'icm42688', label: 'ICM42688' },
          { value: 'custom', label: '+ Other Gyro' }
        ]
      },
      {
        id: 'weight',
        label: 'Weight',
        placeholder: 'Example: 7g',
        type: 'text'
      },
      {
        id: 'features',
        label: 'Features',
        type: 'select',
        options: [
          { value: 'osd', label: 'OSD' },
          { value: 'osd-blackbox', label: 'OSD + Blackbox' },
          { value: 'osd-blackbox-sd', label: 'OSD + Blackbox + SD' },
          { value: 'osd-baro', label: 'OSD + Barometer' },
          { value: 'osd-gps', label: 'OSD + GPS' },
          { value: 'custom', label: '+ Custom Features' }
        ]
      },
      {
        id: 'voltage',
        label: 'Input Voltage',
        type: 'select',
        options: [
          { value: '2-6s', label: '2-6S' },
          { value: '3-6s', label: '3-6S' },
          { value: '4-8s', label: '4-8S' },
          { value: '6-12s', label: '6-12S' },
          { value: 'custom', label: '+ Custom Voltage Range' }
        ]
      },
      {
        id: 'url',
        label: 'Product URL (optional)',
        placeholder: 'Link to where you found this component',
        type: 'url'
      },
      {
        id: 'price',
        label: 'Price',
        placeholder: 'Enter price in USD',
        type: 'number'
      }
    ]
  },
  {
    id: 'receiver',
    title: 'Receiver',
    description: 'Control and telemetry',
    infoCards: [
      {
        title: 'Protocol',
        description: 'Different protocols offer varying levels of range, latency, and features.'
      },
      {
        title: 'Range',
        description: 'Consider your flying style - long range needs different hardware than freestyle.'
      },
      {
        title: 'Telemetry',
        description: 'Two-way communication allows monitoring of vital flight data.'
      }
    ],
    options: [
      {
        name: 'Happymodel TCXO ExpressLRS 2.4GHz Nano Receiver - EP1',
        image: '/byod/3and5inch_reciever.jpg',
        description: 'Ultra lightweight high performance receiver',
        specs: {
          protocol: 'ExpressLRS 2.4GHz',
          type: 'ISM 2.4GHz',
          mcu: 'ESP8285',
          rfModule: 'SX1280',
          range: '10km+',
          weight: '0.41g (without antenna)',
          voltage: '5V',
          dimension: '10mm x 10mm x 6mm',
          refreshRate: '25Hz-500Hz',
          antennaGain: '2.23dB',
          telemetry: 'Yes',
          price: 19.99
        }
      }
    ],
    customFields: [
      {
        id: 'name',
        label: 'Receiver',
        placeholder: 'Example: TBS Crossfire Nano',
        type: 'text'
      },
      {
        id: 'protocol',
        label: 'Protocol',
        type: 'select',
        options: [
          { value: 'crsf', label: 'CRSF' },
          { value: 'elrs', label: 'ExpressLRS' },
          { value: 'frsky', label: 'FrSky' },
          { value: 'custom', label: '+ Other Protocol' }
        ]
      },
      {
        id: 'range',
        label: 'Range',
        type: 'select',
        options: [
          { value: '1km', label: '1km' },
          { value: '5km', label: '5km' },
          { value: '10km', label: '10km+' },
          { value: '30km', label: '30km+' },
          { value: 'custom', label: '+ Custom Range' }
        ]
      },
      {
        id: 'weight',
        label: 'Weight',
        placeholder: 'Example: 1.5g',
        type: 'text'
      },
      {
        id: 'voltage',
        label: 'Input Voltage',
        type: 'select',
        options: [
          { value: '3.3', label: '3.3V' },
          { value: '5', label: '5V' },
          { value: '3.3-5V', label: '3.3-5V' },
          { value: '5-10V', label: '5-10V' },
          { value: 'custom', label: '+ Custom Voltage Range' }
        ]
      },
      {
        id: 'telemetry',
        label: 'Telemetry Support',
        type: 'select',
        options: [
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
          { value: 'custom', label: '+ Custom Telemetry' }
        ]
      },
      {
        id: 'url',
        label: 'Product URL (optional)',
        placeholder: 'Link to where you found this component',
        type: 'url'
      },
      {
        id: 'price',
        label: 'Price',
        placeholder: 'Enter price in USD',
        type: 'number'
      }
    ]
  },
  {
    id: 'battery',
    title: 'Battery',
    description: 'Power and flight time',
    infoCards: [
      {
        title: 'Cell Count',
        description: 'Higher cell count (S rating) means more power but also more weight.'
      },
      {
        title: 'Capacity',
        description: 'Larger capacity means longer flight times but adds weight.'
      },
      {
        title: 'C Rating',
        description: 'Higher C rating allows for more current draw, important for high-performance builds.'
      }
    ],
    options: [
      {
        name: 'XILO 650mAh 4S 100c Essential Micro LiPo Battery',
        image: '/byod/3inch_battery.jpg',
        description: 'Lightweight micro battery for 3-inch builds',
        specs: {
          cells: '4S',
          capacity: '650mAh',
          cRating: '100C',
          weight: '80g',
          voltage: '14.8V',
          dimensions: '60mm x 27mm x 24mm',
          connector: 'XT30 (16awg wire)',
          features: 'Quality grade-A cells, Compact Size, Black design',
          price: 19.99
        }
      },
      {
        name: 'XILO 1250mAh 6S 100c Basher LiPo Battery',
        image: '/byod/5inch_battery.jpg',
        description: 'Powerful battery for 5-inch freestyle builds',
        specs: {
          cells: '6S',
          capacity: '1250mAh',
          cRating: '100C',
          weight: '215g',
          voltage: '22.2V',
          dimensions: '80mm x 43mm x 33mm',
          connector: 'XT60 (12awg wire)',
          features: 'Quality grade-A cells, Compact Size, Black design',
          price: 34.99
        }
      }
    ],
    customFields: [
      {
        id: 'name',
        label: 'Battery',
        placeholder: 'Example: Tattu R-Line 4S',
        type: 'text'
      },
      {
        id: 'cells',
        label: 'Cell Count',
        type: 'select',
        options: [
          { value: '3s', label: '3S (11.1V)' },
          { value: '4s', label: '4S (14.8V)' },
          { value: '5s', label: '5S (18.5V)' },
          { value: '6s', label: '6S (22.2V)' },
          { value: '8s', label: '8S (29.6V)' },
          { value: 'custom', label: '+ Custom Cell Count' }
        ]
      },
      {
        id: 'capacity',
        label: 'Capacity',
        type: 'select',
        options: [
          { value: '850', label: '850mAh' },
          { value: '1000', label: '1000mAh' },
          { value: '1300', label: '1300mAh' },
          { value: '1500', label: '1500mAh' },
          { value: '1800', label: '1800mAh' },
          { value: '2200', label: '2200mAh' },
          { value: 'custom', label: '+ Custom Capacity' }
        ]
      },
      {
        id: 'cRating',
        label: 'C Rating',
        type: 'select',
        options: [
          { value: '75c', label: '75C' },
          { value: '95c', label: '95C' },
          { value: '100c', label: '100C' },
          { value: '120c', label: '120C' },
          { value: 'custom', label: '+ Custom C Rating' }
        ]
      },
      {
        id: 'weight',
        label: 'Weight',
        placeholder: 'Example: 180g',
        type: 'text'
      },
      {
        id: 'voltage',
        label: 'Nominal Voltage',
        type: 'select',
        options: [
          { value: '7.4', label: '7.4V' },
          { value: '11.1', label: '11.1V' },
          { value: '14.8', label: '14.8V' },
          { value: '18.5', label: '18.5V' },
          { value: '22.2', label: '22.2V' },
          { value: '29.6', label: '29.6V' },
          { value: '44.4', label: '44.4V' },
          { value: 'custom', label: '+ Custom Voltage' }
        ]
      },
      {
        id: 'url',
        label: 'Product URL (optional)',
        placeholder: 'Link to where you found this component',
        type: 'url'
      },
      {
        id: 'price',
        label: 'Price',
        placeholder: 'Enter price in USD',
        type: 'number'
      }
    ]
  },
  {
    id: 'bom',
    title: 'Summary',
    description: 'Review your build',
    customFields: []
  }
];

const componentIntros = {
  frame: {
    icon: '🛠️',
    title: 'Frame Selection',
    description: 'Choose the perfect frame size and style for your build',
    color: '#00F135',
    animation: {
      initial: { scale: 0, rotate: -180 },
      animate: { scale: 1, rotate: 0 },
      transition: { type: 'spring', stiffness: 200, damping: 20 }
    }
  },
  motors: {
    icon: '⚡',
    title: 'Motor Configuration',
    description: 'Select the right motors for optimal performance',
    color: '#FF3D00',
    animation: {
      initial: { y: 100, opacity: 0 },
      animate: { y: 0, opacity: 1 },
      transition: { type: 'spring', stiffness: 200, damping: 20 }
    }
  },
  esc: {
    icon: '🔌',
    title: 'ESC Setup',
    description: 'Configure your electronic speed controllers',
    color: '#2196F3',
    animation: {
      initial: { x: -100, opacity: 0 },
      animate: { x: 0, opacity: 1 },
      transition: { type: 'spring', stiffness: 200, damping: 20 }
    }
  },
  fc: {
    icon: '🧠',
    title: 'Flight Controller',
    description: 'Choose your drone\'s brain',
    color: '#9C27B0',
    animation: {
      initial: { scale: 2, opacity: 0 },
      animate: { scale: 1, opacity: 1 },
      transition: { type: 'spring', stiffness: 200, damping: 20 }
    }
  },
  receiver: {
    icon: '📡',
    title: 'Receiver Selection',
    description: 'Set up your control link',
    color: '#FFC107',
    animation: {
      initial: { rotate: 90, opacity: 0 },
      animate: { rotate: 0, opacity: 1 },
      transition: { type: 'spring', stiffness: 200, damping: 20 }
    }
  },
  battery: {
    icon: '🔋',
    title: 'Power System',
    description: 'Choose your battery configuration',
    color: '#4CAF50',
    animation: {
      initial: { y: -100, opacity: 0 },
      animate: { y: 0, opacity: 1 },
      transition: { type: 'spring', stiffness: 200, damping: 20 }
    }
  },
  bom: {
    icon: '📋',
    title: 'Build Summary',
    description: 'Review your configuration',
    color: '#4CAF50',
    animation: {
      initial: { scale: 0, opacity: 0 },
      animate: { scale: 1, opacity: 1 },
      transition: { type: 'spring', stiffness: 200, damping: 20 }
    }
  }
};

// Add tutorial content after componentIntros
const tutorialContent = {
  frame: [
    {
      title: 'Frame Size',
      description: 'The frame size determines your drone\'s overall dimensions. Measured diagonally in millimeters, it affects stability, agility, and what components you can use. Smaller frames (3") are great for indoor flying, while larger ones (7"+) excel at long-range flights.',
      icon: '📏'
    },
    {
      title: 'Wheelbase',
      description: 'Wheelbase is the distance between diagonal motors. A longer wheelbase provides more stability but reduces agility. This measurement is crucial for selecting appropriate props and determining flight characteristics.',
      icon: '🔄'
    },
    {
      title: 'Material & Durability',
      description: 'Most frames use carbon fiber for its excellent strength-to-weight ratio. The thickness of the arms and plates affects both durability and weight. Thicker carbon fiber (4mm+) offers better crash resistance but adds weight.',
      icon: '🛡️'
    }
  ],
  motors: [
    {
      title: 'Motor Size',
      description: 'Motor size (e.g., 2206) indicates the stator width (22mm) and height (06mm). Larger motors produce more power but add weight. Match motor size to your frame and flying style.',
      icon: '⚡'
    },
    {
      title: 'KV Rating',
      description: 'KV rating determines how fast the motor spins per volt. Lower KV (1700-2000) is better for larger props and efficiency, while higher KV (2300+) provides more speed and agility with smaller props.',
      icon: '🔄'
    },
    {
      title: 'Power Efficiency',
      description: 'Consider the balance between power and efficiency. More powerful motors drain batteries faster but provide better performance. Modern motors often feature special winding techniques and materials for better efficiency.',
      icon: '⚡'
    }
  ],
  esc: [
    {
      title: 'Current Rating',
      description: 'ESC current rating (in Amps) must exceed your motor\'s maximum current draw. For safety, choose ESCs rated 20% higher than your motor\'s peak current draw.',
      icon: '⚡'
    },
    {
      title: 'Protocols',
      description: 'Modern ESCs support various protocols like DShot1200 for precise motor control. Higher protocols offer better resolution and faster response times.',
      icon: '📡'
    },
    {
      title: 'Firmware Features',
      description: 'BLHeli_32 offers advanced features like RPM filtering and telemetry. Consider firmware compatibility with your flight controller and desired features.',
      icon: '💻'
    }
  ],
  fc: [
    {
      title: 'Processor Speed',
      description: 'The flight controller\'s processor (F4, F7, H7) determines how fast it can run flight calculations. Faster processors allow higher loop rates and more features.',
      icon: '🧠'
    },
    {
      title: 'Gyro Performance',
      description: 'The gyro sensor quality affects flight precision and smoothness. Modern gyros like BMI270 and ICM42688 offer excellent performance and noise handling.',
      icon: '🎯'
    },
    {
      title: 'Additional Features',
      description: 'Look for built-in features like OSD (On-Screen Display), blackbox logging, and multiple UARTs for connecting peripherals.',
      icon: '🎛️'
    }
  ],
  receiver: [
    {
      title: 'Protocol Range',
      description: 'Different protocols offer varying range capabilities. ExpressLRS and Crossfire excel at long range, while FrSky is great for typical freestyle flying.',
      icon: '📡'
    },
    {
      title: 'Latency',
      description: 'Lower latency means faster response to your inputs. Modern protocols can achieve sub-10ms latency for extremely precise control.',
      icon: '⚡'
    },
    {
      title: 'Telemetry',
      description: 'Two-way communication allows real-time flight data like battery voltage, signal strength, and GPS coordinates to be sent back to your radio.',
      icon: '📊'
    }
  ],
  battery: [
    {
      title: 'Cell Count',
      description: 'More cells (higher S rating) means more power but also more weight. 4S is great for beginners, while 6S offers maximum performance for experienced pilots.',
      icon: '🔋'
    },
    {
      title: 'Capacity',
      description: 'Battery capacity (mAh) determines flight time. Larger capacity means longer flights but adds weight. Balance capacity with your power needs and weight limits.',
      icon: '⏱️'
    },
    {
      title: 'C Rating',
      description: 'C rating indicates how much current the battery can safely deliver. Higher C ratings allow for more aggressive flying but may reduce battery life.',
      icon: '⚡'
    }
  ],
  // Add tutorial content for the BOM step
  bom: [
    {
      title: 'Build Summary',
      description: 'Review your complete build configuration. Here you can see all the components you\'ve selected and their specifications. You can also export your build for future reference.',
      icon: '📋'
    },
    {
      title: 'Cost Analysis',
      description: 'See the total cost of your build broken down by component. This helps you understand where your budget is going and make adjustments if needed.',
      icon: '💰'
    },
    {
      title: 'Export Options',
      description: 'You can download your build as a text file or email it to yourself for future reference. This makes it easy to share your build with others or keep track of it for later.',
      icon: '📤'
    }
  ]
};

// Add common options for each component type
const commonOptions = {
  frame: {
    size: ['3 inch', '4 inch', '5 inch', '6 inch', '7 inch', '8 inch', '9 inch', '10 inch'],
    wheelbase: ['150mm', '180mm', '220mm', '250mm', '280mm', '300mm', '350mm'],
    motorMounts: ['16x16mm', '19x19mm', '25x25mm', '30.5x30.5mm'],
    fcMount: ['20x20mm', '25.5x25.5mm', '30.5x30.5mm', '35x35mm'],
    weight: ['50g', '70g', '100g', '120g', '150g', '200g', '250g']
  },
  motors: {
    size: ['1106', '1404', '1506', '1606', '2204', '2205', '2206', '2207', '2306', '2307', '2405', '2406', '2407', '2507', '2508'],
    kv: ['1300KV', '1500KV', '1700KV', '1750KV', '1800KV', '1850KV', '2000KV', '2200KV', '2300KV', '2400KV', '2600KV', '2750KV'],
    maxThrust: ['500g', '750g', '1000g', '1200g', '1500g', '1800g', '2000g'],
    maxCurrent: ['15A', '20A', '25A', '30A', '35A', '40A', '45A'],
    weight: ['6g', '8g', '10g', '12g', '15g', '18g', '20g', '25g', '30g']
  },
  esc: {
    current: ['20A', '25A', '30A', '35A', '40A', '45A', '50A', '60A', '65A'],
    voltage: ['2-4S', '3-6S', '4-8S', '6-12S'],
    firmware: ['BLHeli_32', 'BLHeli_S', 'KISS', 'AM32', 'Bluejay'],
    protocols: ['DShot300', 'DShot600', 'DShot1200', 'Multishot', 'Oneshot125', 'Oneshot42'],
    weight: ['2g', '4g', '6g', '8g', '10g', '12g', '15g']
  },
  fc: {
    processor: ['F4', 'F7', 'H7', 'G4'],
    gyro: ['MPU6000', 'ICM20602', 'BMI270', 'ICM42688'],
    features: ['OSD', 'OSD + Blackbox', 'OSD + Blackbox + SD', 'OSD + Barometer', 'OSD + GPS'],
    voltage: ['2-6S', '3-6S', '4-8S', '6-12S'],
    weight: ['3g', '4g', '5g', '6g', '7g', '8g', '10g']
  },
  receiver: {
    protocol: ['ELRS 2.4GHz', 'ELRS 915MHz', 'Crossfire', 'Ghost', 'FrSky', 'Spektrum'],
    range: ['1km', '2km', '5km', '10km', '20km', '30km+'],
    voltage: ['3.3V', '5V', '3.3-5V', '5-10V'],
    telemetry: ['Yes', 'No'],
    weight: ['0.5g', '1g', '1.5g', '2g', '3g', '4g']
  },
  battery: {
    cells: ['2S', '3S', '4S', '5S', '6S', '8S', '12S'],
    capacity: ['450mAh', '650mAh', '850mAh', '1000mAh', '1300mAh', '1500mAh', '1800mAh', '2200mAh'],
    cRating: ['75C', '95C', '100C', '120C', '150C'],
    voltage: ['7.4V', '11.1V', '14.8V', '18.5V', '22.2V', '29.6V', '44.4V'],
    weight: ['50g', '100g', '150g', '200g', '250g', '300g', '350g', '400g']
  }
};

export const BuildYourDrone: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedComponents, setSelectedComponents] = useState<Record<string, any>>({});
  const [customComponent, setCustomComponent] = useState<Record<string, any>>({});
  const [customInputs, setCustomInputs] = useState<Record<string, boolean>>({});
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [welcomeStep, setWelcomeStep] = useState(0);
  const [showComponentIntro, setShowComponentIntro] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(0);
  const [hasCompletedWelcome, setHasCompletedWelcome] = useState(false);
  const [expandedCards, setExpandedCards] = useState<string[]>([]);
  const navigate = useNavigate();

  // Welcome sequence content
  const welcomeSequence = [
    {
      title: "Welcome to",
      subtitle: "DroneForge",
      description: null,
      icon: null,
      duration: 2000
    },
    {
      title: "Build Your",
      subtitle: "Perfect Drone",
      description: "Custom-designed for your needs",
      icon: "🚁",
      duration: 2000
    },
    {
      title: "Let's Begin",
      subtitle: "with the Frame",
      description: "The foundation of your build",
      icon: "🛠️",
      duration: 2000
    }
  ];

  // Handle welcome sequence
  useEffect(() => {
    if (!hasCompletedWelcome) {
      if (welcomeStep < welcomeSequence.length) {
        const timer = setTimeout(() => {
          setWelcomeStep(prev => prev + 1);
        }, welcomeSequence[welcomeStep].duration);

        return () => clearTimeout(timer);
      } else {
        setHasCompletedWelcome(true);
        setShowWelcome(false);
        // Don't show component intro for frame immediately
        setTimeout(() => {
          setShowTutorial(true);
        }, 300);
      }
    }
  }, [welcomeStep, hasCompletedWelcome]);

  // Handle component transitions
  const handleNext = () => {
    if (currentStep < buildSteps.length - 1 && !isTransitioning) {
      setIsTransitioning(true);
      setShowTutorial(false);
      
      // Show component intro animation
      setCurrentStep(prev => prev + 1);
      setShowComponentIntro(true);
      
      // After intro, show tutorial modal
      setTimeout(() => {
        setShowComponentIntro(false);
        setIsTransitioning(false);
        // Add small delay before showing tutorial
        setTimeout(() => {
          setShowTutorial(true);
        }, 300);
      }, 2000);
    }
  };

  const handleBack = () => {
    if (currentStep > 0 && !isTransitioning) {
      setIsTransitioning(true);
      setShowTutorial(false);
      
      // Show component intro animation
      setCurrentStep(prev => prev - 1);
      setShowComponentIntro(true);
      
      // After intro, show tutorial modal
      setTimeout(() => {
        setShowComponentIntro(false);
        setIsTransitioning(false);
        // Add small delay before showing tutorial
        setTimeout(() => {
          setShowTutorial(true);
        }, 300);
      }, 2000);
    }
  };

  const handleComponentSelect = (component: any) => {
    // Store the component with its price
    setSelectedComponents(prev => ({
      ...prev,
      [buildSteps[currentStep].id]: {
        ...component,
        price: component.specs.price
      }
    }));

    // Populate custom fields with selected component's values
    const componentType = buildSteps[currentStep].id;
    const newCustomComponent = { ...customComponent };
    
    // Map the selected component's specs to custom fields
    Object.entries(component.specs).forEach(([key, value]) => {
      newCustomComponent[key] = value;
    });

    // Ensure the name is set from the component
    newCustomComponent.name = component.name;
    
    // Store price separately
    newCustomComponent.price = component.specs.price;

    setCustomComponent(newCustomComponent);
    // Reset custom inputs state since we're using pre-made values
    setCustomInputs({});
  };

  const handleCustomInputChange = (field: string, value: string) => {
    if (value === 'custom') {
      setCustomInputs(prev => ({
        ...prev,
        [field]: true
      }));
    } else {
      setCustomInputs(prev => ({
        ...prev,
        [field]: false
      }));
      
      // Update custom component data
      setCustomComponent(prev => ({
        ...prev,
        [field]: value
      }));
      
      // If this is a complete enough custom component, update selectedComponents
      // Only if the price is set, consider it complete enough to save
      if (field === 'price' && value) {
        setSelectedComponents(prev => ({
          ...prev,
          [buildSteps[currentStep].id]: {
            ...customComponent,
            [field]: value,
            name: customComponent.name || `Custom ${buildSteps[currentStep].title}`
          }
        }));
      }
    }

    // Clear pre-made selection only when editing a field other than price
    // to avoid clearing when just setting the price
    if (field !== 'price' && Object.keys(selectedComponents).includes(buildSteps[currentStep].id)) {
      setSelectedComponents(prev => {
        const updated = { ...prev };
        delete updated[buildSteps[currentStep].id];
        return updated;
      });
    }
  };

  // Helper function to render input field based on type
  const renderInputField = (field: any) => {
    const componentType = buildSteps[currentStep].id;
    const options = commonOptions[componentType as keyof typeof commonOptions][field.id as keyof (typeof commonOptions)[keyof typeof commonOptions]] || [];
    const currentValue = customComponent[field.id] || '';

    // If it's a name field and has select type, render as dropdown
    if (field.id === 'name' && field.type === 'select') {
      return (
        <div className="relative">
          <select
            value={currentValue}
            onChange={(e) => handleCustomInputChange(field.id, e.target.value)}
            className="w-full bg-white/[0.03] border border-white/10 rounded-xl px-4 py-2.5 text-white text-sm focus:outline-none focus:border-[#00F135] focus:bg-white/[0.05] transition-all appearance-none cursor-pointer"
          >
            <option value="" className="bg-[#1a1a1a] text-white">Select {field.label}</option>
            {field.options?.map((option: any) => (
              <option key={option.value} value={option.label} className="bg-[#1a1a1a] text-white">
                {option.label}
              </option>
            ))}
          </select>
          <div className="absolute right-4 top-1/2 -translate-y-1/2 pointer-events-none text-white/40">
            ▼
          </div>
        </div>
      );
    }

    // For fields with type "select"
    if (field.type === 'select') {
      return (
        <div className="relative">
          <select
            value={currentValue}
            onChange={(e) => handleCustomInputChange(field.id, e.target.value)}
            className="w-full bg-white/[0.03] border border-white/10 rounded-xl px-4 py-2.5 text-white text-sm focus:outline-none focus:border-[#00F135] focus:bg-white/[0.05] transition-all appearance-none cursor-pointer"
          >
            <option value="" className="bg-[#1a1a1a] text-white">Select {field.label}</option>
            {field.options?.map((option: any) => (
              <option key={option.value} value={option.value === 'custom' ? 'custom' : option.label} className="bg-[#1a1a1a] text-white">
                {option.label}
              </option>
            ))}
          </select>
          <div className="absolute right-4 top-1/2 -translate-y-1/2 pointer-events-none text-white/40">
            ▼
          </div>
        </div>
      );
    }

    // For text, number, url inputs or when custom input is selected
    return (
      <div className="relative">
        {customInputs[field.id] ? (
          <div className="relative">
            <input
              type="text"
              value={currentValue}
              onChange={(e) => handleCustomInputChange(field.id, e.target.value)}
              className="w-full bg-white/[0.03] border border-white/10 rounded-xl px-4 py-2.5 text-white text-sm placeholder-white/40 focus:outline-none focus:border-[#00F135] focus:bg-white/[0.05] transition-all"
              placeholder={`Custom ${field.label}`}
            />
            <button
              onClick={() => setCustomInputs(prev => ({ ...prev, [field.id]: false }))}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-white/40 hover:text-white"
            >
              ↩
            </button>
          </div>
        ) : (
          <input
            type={field.type}
            value={currentValue}
            onChange={(e) => handleCustomInputChange(field.id, e.target.value)}
            className="w-full bg-white/[0.03] border border-white/10 rounded-xl px-4 py-2.5 text-white text-sm placeholder-white/40 focus:outline-none focus:border-[#00F135] focus:bg-white/[0.05] transition-all"
            placeholder={field.placeholder || `Enter ${field.label}`}
          />
        )}
      </div>
    );
  };

  // Add helper function to safely get component intro
  const getComponentIntro = (stepId: string) => {
    return componentIntros[stepId as keyof typeof componentIntros] || {
      icon: '🔧',
      title: 'Configuration',
      description: 'Build your drone',
      color: '#757575',
      animation: {
        initial: { scale: 0 },
        animate: { scale: 1 },
        transition: { duration: 0.3 }
      }
    };
  };

  // Update where we use componentIntros
  const getCurrentIntro = () => {
    const stepId = buildSteps[currentStep].id;
    return getComponentIntro(stepId);
  };

  // Add tutorial handlers inside the component
  const handleTutorialNext = () => {
    const currentContent = tutorialContent[buildSteps[currentStep].id as keyof typeof tutorialContent];
    if (currentContent && tutorialStep < currentContent.length - 1) {
      setTutorialStep(prev => prev + 1);
    } else {
      setShowTutorial(false);
      setTutorialStep(0);
    }
  };

  const handleTutorialSkip = () => {
    setShowTutorial(false);
    setTutorialStep(0);
  };

  // Toggle card expansion
  const toggleCardExpansion = (cardName: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent card selection
    setExpandedCards(prev => 
      prev.includes(cardName) 
        ? prev.filter(name => name !== cardName) 
        : [...prev, cardName]
    );
  };

  // Add BOM summary component
  const BOMSummary: React.FC<{ components: Record<string, any> }> = ({ components }) => {
    const getTotalPrice = (): number => {
      return Object.values(components || {}).reduce((total: number, component: any) => {
        return total + (Number(component?.price) || 0);
      }, 0);
    };

    const getTotalWeight = (): number => {
      return Object.values(components || {}).reduce((total: number, component: any) => {
        // Extract numeric part from weight strings like "80g" or just use the number if already numeric
        const weightString = component?.weight || component?.specs?.weight || "0";
        const weight = parseFloat(weightString.toString().replace(/[^\d.-]/g, '')) || 0;
        return total + weight;
      }, 0);
    };

    // Format specs to readable text, filtering out only important specs
    const formatSpecs = (details: any) => {
      // Keys we want to display in order of importance
      const keyOrder = ['size', 'weight', 'kv', 'cells', 'capacity', 'voltage', 'current', 'maxThrust', 'gyro', 'processor'];
      
      // Combine specs from both details and details.specs
      const combinedSpecs = { ...details };
      if (details.specs && typeof details.specs === 'object') {
        Object.entries(details.specs).forEach(([key, value]) => {
          if (!combinedSpecs[key]) {
            combinedSpecs[key] = value;
          }
        });
      }

      // Filter and sort specs
      return Object.entries(combinedSpecs)
        .filter(([key, value]) => 
          keyOrder.includes(key) && 
          value !== undefined && 
          value !== null && 
          key !== 'name' && 
          key !== 'price' && 
          key !== 'image' && 
          !key.includes('image') &&
          !String(value).includes('/')  // Skip paths
        )
        .sort((a, b) => keyOrder.indexOf(a[0]) - keyOrder.indexOf(b[0]))
        .map(([key, value]) => {
          // Format the key to be more readable
          const formattedKey = key.charAt(0).toUpperCase() + key.slice(1)
            .replace(/([A-Z])/g, ' $1') // Add space before capitals
            .replace('Kv', 'KV'); // Fix KV capitalization
          
          // Format the value
          let formattedValue = value;
          if (typeof value === 'object') {
            formattedValue = JSON.stringify(value).replace(/[{}"]/g, '');
          }
          
          return { key: formattedKey, value: formattedValue };
        });
    };

    const handleExport = () => {
      // Format components for text export
      const sections = Object.entries(components).map(([type, details]: [string, any]) => {
        const specs = formatSpecs(details);
        
        return `
${type.toUpperCase()}:
${details?.name || 'Custom Build'}
${specs.map(spec => `${spec.key}: ${spec.value}`).join('\n')}
Price: $${details?.price || 'N/A'}`;
      }).join('\n\n');

      const bomContent = `DroneForge Build Summary

${sections}

Total Estimated Cost: $${getTotalPrice().toFixed(2)}
Total Estimated Weight: ${getTotalWeight()}g
`;

      const blob = new Blob([bomContent], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'drone-build-summary.txt';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };

    const handleEmailBOM = () => {
      // Format components for email
      const sections = Object.entries(components).map(([type, details]: [string, any]) => {
        const specs = formatSpecs(details);
        
        return `
${type.toUpperCase()}:
${details?.name || 'Custom Build'}
${specs.map(spec => `${spec.key}: ${spec.value}`).join('\n')}
Price: $${details?.price || 'N/A'}`;
      }).join('\n\n');

      const body = `DroneForge Build Summary

${sections}

Total Estimated Cost: $${getTotalPrice().toFixed(2)}
Total Estimated Weight: ${getTotalWeight()}g
`;

      const subject = 'DroneForge Build Summary';
      const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      
      // Open in new window to handle larger emails
      window.open(mailtoLink, '_blank');
    };

    // Check if there are any components selected
    const hasComponents = Object.keys(components).length > 0;

    return (
      <div className="space-y-6">
        {/* Component Summary Cards */}
        {hasComponents ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.entries(components).map(([type, details]: [string, any]) => (
              type !== 'bom' && (
                <motion.div
                  key={type}
                  className="bg-white/[0.03] backdrop-blur-sm rounded-xl p-4 border border-white/5"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <div className="flex justify-between items-start mb-2">
                    <h3 className="text-white font-medium">{type.charAt(0).toUpperCase() + type.slice(1)}</h3>
                    <span className="text-[#00F135] text-sm">${details?.price || 'N/A'}</span>
                  </div>
                  <p className="text-white/60 text-sm mb-2">{details?.name || 'Custom Build'}</p>
                  <div className="grid grid-cols-2 gap-x-4 gap-y-1">
                    {formatSpecs(details).map((spec, i) => (
                      <div key={i} className="text-xs">
                        <span className="text-white/40">{spec.key}: </span>
                        <span className="text-white/80">{String(spec.value)}</span>
                      </div>
                    ))}
                  </div>
                </motion.div>
              )
            ))}
          </div>
        ) : (
          <div className="bg-white/[0.03] backdrop-blur-sm rounded-xl p-8 border border-white/5 text-center">
            <div className="text-4xl mb-4">📋</div>
            <h3 className="text-white font-medium text-xl mb-2">No Components Selected</h3>
            <p className="text-white/60 mb-4">
              It looks like you haven't selected any components for your build.
              Go back through the previous steps to select components.
            </p>
          </div>
        )}

        {/* Total Summary Card */}
        {hasComponents && (
          <div className="bg-white/[0.03] backdrop-blur-sm rounded-xl p-5 border border-white/5">
            <h3 className="text-white font-medium text-lg mb-3">Build Summary</h3>
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <span className="text-white/70">Total Estimated Cost</span>
                <span className="text-xl text-[#00F135] font-bold">${getTotalPrice().toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-white/70">Total Estimated Weight</span>
                <span className="text-xl text-white font-bold">{getTotalWeight()}g</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-white/70">Components</span>
                <span className="text-xl text-white font-bold">{Object.keys(components).length}</span>
              </div>
            </div>
          </div>
        )}

        {/* Export Options */}
        {hasComponents && (
          <div className="flex gap-4">
            <DroneforgeButton
              variant="secondary"
              size="md"
              onClick={handleExport}
              className="flex-1 rounded-xl"
            >
              <span className="flex items-center gap-2">
                <span>Download BOM</span>
                <HiOutlineArrowUpRight />
              </span>
            </DroneforgeButton>

            <DroneforgeButton
              variant="accent"
              size="md"
              onClick={handleEmailBOM}
              className="flex-1 rounded-xl"
            >
              <span className="flex items-center gap-2">
                <span>Email BOM</span>
                <HiOutlineArrowUpRight />
              </span>
            </DroneforgeButton>
          </div>
        )}
      </div>
    );
  };

  // Update the main component's return statement to include BOM when on last step
  return (
    <motion.div className="w-full min-h-screen bg-transparent overflow-hidden p-4 md:p-8 flex items-center justify-center">
      {/* Main Container with Enhanced Border */}
      <motion.div 
        className="relative w-full max-w-[1800px] h-[90vh] rounded-2xl overflow-hidden"
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.7 }}
      >
        {/* Backdrop Layer */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/40 via-black/30 to-black/40">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,transparent_0%,rgba(0,0,0,0.4)_100%)]" />
        </div>

        {/* Animated Border Gradient */}
        <div className="absolute inset-0 bg-gradient-to-b from-white/10 via-[#00F135]/5 to-white/5 p-[1px] rounded-2xl">
          <div className="absolute inset-0 bg-gradient-to-r from-white/10 via-[#00F135]/10 to-white/10">
            <div className="absolute inset-0 animate-pulse bg-[linear-gradient(to_right,transparent,rgba(255,255,255,0.1),transparent)]" />
          </div>
        </div>

        {/* Glass Background with Enhanced Depth Effect */}
        <div className="absolute inset-[1px] rounded-2xl bg-black/30 backdrop-blur-md">
          {/* Welcome Overlay */}
          <AnimatePresence>
            {showWelcome && (
              <motion.div
                className="absolute inset-0 z-50 flex items-center justify-center bg-black/90 backdrop-blur-xl rounded-2xl"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="relative w-full h-full flex items-center justify-center">
                  {/* Background Effects */}
                  <motion.div 
                    className="absolute inset-0 opacity-30"
                    animate={{
                      background: [
                        'radial-gradient(circle at center, #00F135 0%, transparent 50%)',
                        'radial-gradient(circle at center, #00F135 20%, transparent 70%)',
                        'radial-gradient(circle at center, #00F135 0%, transparent 50%)'
                      ]
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  />

                  {/* Welcome Content */}
                  <AnimatePresence mode="wait">
              <motion.div
                      key={welcomeStep}
                      className="text-center z-10"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                      {/* Icon */}
                      {welcomeSequence[welcomeStep]?.icon && (
                        <motion.div
                          className="text-6xl mb-6"
                          initial={{ scale: 0.5, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          transition={{ duration: 0.5, delay: 0.2 }}
                        >
                          {welcomeSequence[welcomeStep].icon}
              </motion.div>
                      )}

                      {/* Title */}
                      <motion.h2
                        className="text-white text-3xl md:text-4xl font-light mb-2"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                      >
                        {welcomeSequence[welcomeStep]?.title}
                      </motion.h2>

                      {/* Subtitle */}
                      <motion.h1
                        className="text-4xl md:text-6xl font-bold mb-4"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                      >
                        <span className="text-[#00F135]">
                          {welcomeSequence[welcomeStep]?.subtitle}
                        </span>
                      </motion.h1>

                      {/* Description */}
                      {welcomeSequence[welcomeStep]?.description && (
                        <motion.p
                          className="text-gray-400 text-lg md:text-xl"
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.5, delay: 0.3 }}
                        >
                          {welcomeSequence[welcomeStep].description}
                        </motion.p>
                      )}
                    </motion.div>
                  </AnimatePresence>

                  {/* Progress Dots */}
                  <div className="absolute bottom-12 left-0 right-0 flex justify-center gap-2">
                    {welcomeSequence.map((_, index) => (
                      <motion.div
                        key={index}
                        className={`w-2 h-2 rounded-full ${
                          index === welcomeStep ? 'bg-[#00F135]' : 'bg-white/20'
                        }`}
                        initial={false}
                        animate={{
                          scale: index === welcomeStep ? 1.2 : 1,
                        }}
                        transition={{ duration: 0.3 }}
                      />
                    ))}
            </div>
          </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Component Introduction Animation */}
          <AnimatePresence>
            {showComponentIntro && (
                  <motion.div
                className="absolute inset-0 z-40 flex items-center justify-center bg-black/80 backdrop-blur-xl"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <motion.div
                  className="text-center p-8 rounded-2xl"
                  key={currentStep}
                  {...getCurrentIntro().animation}
                >
                  <motion.div 
                    className="text-8xl mb-6"
                    animate={{ 
                      scale: [1, 1.2, 1],
                      rotate: [0, 5, -5, 0]
                    }}
                    transition={{ 
                      duration: 2,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    {getCurrentIntro().icon}
                  </motion.div>
                  <motion.h2 
                    className="text-4xl font-bold mb-4"
                    style={{ 
                      color: getCurrentIntro().color 
                    }}
                  >
                    {getCurrentIntro().title}
                  </motion.h2>
                  <motion.p 
                    className="text-gray-300 text-xl"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    {getCurrentIntro().description}
                  </motion.p>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Main Content */}
          <motion.div 
            className="relative h-full z-10 flex flex-col"
            animate={{ opacity: isTransitioning || showComponentIntro || showTutorial ? 0.3 : 1 }}
            transition={{ duration: 0.3 }}
          >
            {/* Content Area with Enhanced Padding */}
            <div className="flex-1 overflow-y-auto px-6 md:px-10 pb-6 pt-5">
              {/* Tutorial Button */}
              <div className="mb-8">
                <motion.button
                  onClick={() => setShowTutorial(true)}
                  className="mx-auto flex items-center gap-3 px-6 py-3 rounded-xl bg-white/[0.03] border border-white/5 
                           hover:bg-white/[0.05] transition-all duration-300 group"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <span className="text-2xl">
                    {getComponentIntro(buildSteps[currentStep].id).icon}
                  </span>
                  <div className="text-left">
                    <h3 className="text-white font-medium">Need Help?</h3>
                    <p className="text-white/60 text-sm">
                      Click to view the guide for {buildSteps[currentStep].title.toLowerCase()}
                    </p>
                  </div>
                  <motion.div 
                    className="ml-2 text-[#00F135]"
                    animate={{ x: [0, 5, 0] }}
                    transition={{ duration: 1.5, repeat: Infinity }}
                  >
                    →
                  </motion.div>
                </motion.button>
              </div>

              {/* Render BOMSummary for the last step or the normal component selection UI */}
              {currentStep === buildSteps.length - 1 ? (
                <BOMSummary components={selectedComponents} />
              ) : (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                  {/* Left Column: Pre-selected Options */}
                  <motion.div 
                    className="relative space-y-4"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                  >
                    <div className="text-center mb-6">
                      <h3 className="text-white font-semibold text-2xl mb-2">Pre-made Options</h3>
                      <p className="text-white/80 text-sm">Ready-to-build configurations</p>
                    </div>

                    <div className="space-y-4">
                      {buildSteps[currentStep]?.options?.map((option, index) => {
                        const isExpanded = expandedCards.includes(option.name);
                        return (
                        <motion.div
                          key={index}
                          className={`bg-white/[0.03] backdrop-blur-sm rounded-2xl overflow-hidden cursor-pointer border border-white/5 ${
                            selectedComponents[buildSteps[currentStep].id]?.name === option.name
                              ? 'ring-1 ring-[#00F135] bg-white/[0.05]'
                              : 'hover:ring-1 hover:ring-white/20 hover:bg-white/[0.05]'
                          }`}
                          onClick={() => handleComponentSelect(option)}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.5, delay: 0.1 * index }}
                          whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
                          whileTap={{ scale: 0.98 }}
                          layout
                          layoutId={`card-${option.name}`}
                        >
                          <motion.div 
                            className={`flex ${isExpanded ? 'flex-col' : 'h-36'} overflow-hidden`}
                            layout
                          >
                            <motion.div 
                              className={`relative overflow-hidden`}
                              layout
                              animate={{ 
                                height: isExpanded ? "180px" : "100%",
                                width: isExpanded ? "100%" : "33.333%",
                                transition: { duration: 0.4, ease: [0.25, 1, 0.5, 1] }
                              }}
                              style={{ 
                                height: isExpanded ? "180px" : "100%",
                                width: isExpanded ? "100%" : "33.333%",
                                float: isExpanded ? "none" : "left" 
                              }}
                            >
                              <motion.img
                                src={option.image}
                                alt={option.name}
                                className="w-full h-full object-cover"
                                layout
                              />
                              <div className="absolute inset-0 bg-black/20" />
                            </motion.div>
                            <motion.div 
                              className="flex-1 p-4"
                              layout
                            >
                              <motion.div 
                                className="flex justify-between items-start mb-2"
                                layout
                              >
                                <motion.h4 
                                  className="text-white font-medium"
                                  layout
                                >
                                  {option.name}
                                </motion.h4>
                                <motion.button 
                                  className={`text-xs ${isExpanded ? 'bg-white/20 text-white' : 'bg-[#00F135]/20 text-[#00F135]'} px-2 py-0.5 rounded-full border ${isExpanded ? 'border-white/20' : 'border-[#00F135]/20'} hover:bg-[#00F135]/30 transition-colors`}
                                  onClick={(e) => toggleCardExpansion(option.name, e)}
                                  whileHover={{ scale: 1.05 }}
                                  whileTap={{ scale: 0.95 }}
                                  layout
                                >
                                  {isExpanded ? 'COLLAPSE' : 'EXPAND'}
                                </motion.button>
                              </motion.div>
                              <motion.p 
                                className={`text-white/80 text-xs mb-2 ${isExpanded ? '' : 'line-clamp-2'}`}
                                layout
                              >
                                {option.description}
                              </motion.p>
                              <motion.div 
                                className="grid grid-cols-2 gap-x-4 gap-y-1 overflow-y-auto pr-1" 
                                animate={{ 
                                  height: isExpanded ? "auto" : "60px",
                                  transition: { duration: 0.4, ease: [0.25, 1, 0.5, 1] }
                                }}
                                layout
                              >
                                {option.specs && Object.entries(option.specs)
                                  .filter(([key]) => key !== 'name' && key !== Object.keys(option.specs)[0])
                                  .map(([key, value], i) => (
                                  <motion.div 
                                    key={key} 
                                    className="text-xs whitespace-nowrap"
                                    initial={{ opacity: 0 }}
                                    animate={{ 
                                      opacity: 1,
                                      transition: { delay: 0.05 * i }
                                    }}
                                    layout
                                  >
                                    <span className="text-white/60">{key}: </span>
                                    <span className="text-white/90">{String(value)}</span>
                                  </motion.div>
                                ))}
                              </motion.div>
                            </motion.div>
                          </motion.div>
                        </motion.div>
                        );
                      })}
                    </div>
                  </motion.div>

                  {/* Right Column: Custom Input */}
                  <motion.div 
                    className="relative"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                  >
                    <div className="text-center mb-6">
                      <h3 className="text-white font-semibold text-2xl mb-2">Custom Configuration</h3>
                      <p className="text-white/80 text-sm">Build your own setup</p>
                    </div>

                    <motion.div 
                      className="bg-white/[0.03] backdrop-blur-sm rounded-2xl border border-white/5 h-[420px] overflow-hidden"
                      whileHover={{ backgroundColor: "rgba(255, 255, 255, 0.05)" }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="p-5 h-full flex flex-col">
                        <div className="flex-1 overflow-y-auto pr-1 custom-scrollbar" style={{ maxHeight: "380px" }}>
                          <div className="grid grid-cols-2 gap-3">
                            {buildSteps[currentStep].customFields?.map((field, index) => (
                              <motion.div 
                                key={field.id} 
                                className={`relative group ${field.id === 'url' ? 'col-span-2' : ''}`}
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3, delay: 0.1 * index }}
                              >
                                <div className="flex items-center justify-between mb-1">
                                  <label className="text-white font-medium text-sm">
                                    {field.label}
                                  </label>
                              {field.id === 'url' && (
                                    <span className="text-white/60 text-xs">(Optional)</span>
                              )}
                                </div>
                                
                                <div className="relative">
                            {renderInputField(field)}
                          </div>

                                  <div className="mt-0.5 text-[10px] text-white/40 truncate">
                                    {/* Field descriptions - even more condensed */}
                                    {currentStep === 0 && field.id === 'wheelbase' && 'Distance between motors (mm)'}
                                    {currentStep === 0 && field.id === 'motorMounts' && 'Motor mount size'}
                                    {currentStep === 0 && field.id === 'fcMount' && 'FC mount'}
                                    {currentStep === 0 && field.id === 'weight' && 'Weight (g)'}
                                    {currentStep === 0 && field.id === 'name' && 'Frame name/model'}
                                    
                                    {/* Motor descriptions */}
                                    {currentStep === 1 && field.id === 'size' && 'Motor size (WxH)'}
                                    {currentStep === 1 && field.id === 'kv' && 'RPM/V'}
                                    {currentStep === 1 && field.id === 'maxThrust' && 'Max thrust'}
                                    {currentStep === 1 && field.id === 'maxCurrent' && 'Max current'}
                                    {currentStep === 1 && field.id === 'weight' && 'Weight (g)'}
                                    
                                    {/* ESC descriptions */}
                                    {currentStep === 2 && field.id === 'current' && 'Current/motor'}
                                    {currentStep === 2 && field.id === 'voltage' && 'Cell range'}
                                    {currentStep === 2 && field.id === 'firmware' && 'Firmware'}
                                    {currentStep === 2 && field.id === 'protocols' && 'Protocols'}
                                    {currentStep === 2 && field.id === 'weight' && 'Weight (g)'}
                                    
                                    {/* FC descriptions */}
                                    {currentStep === 3 && field.id === 'processor' && 'Processor'}
                                    {currentStep === 3 && field.id === 'gyro' && 'Gyro'}
                                    {currentStep === 3 && field.id === 'features' && 'Features'}
                                    {currentStep === 3 && field.id === 'voltage' && 'Voltage'}
                                    {currentStep === 3 && field.id === 'weight' && 'Weight (g)'}
                                    
                                    {/* Receiver descriptions */}
                                    {currentStep === 4 && field.id === 'protocol' && 'Protocol'}
                                    {currentStep === 4 && field.id === 'range' && 'Range'}
                                    {currentStep === 4 && field.id === 'voltage' && 'Voltage'}
                                    {currentStep === 4 && field.id === 'telemetry' && 'Telemetry'}
                                    {currentStep === 4 && field.id === 'weight' && 'Weight (g)'}
                                    
                                    {/* Battery descriptions */}
                                    {currentStep === 5 && field.id === 'cells' && 'Cells'}
                                    {currentStep === 5 && field.id === 'capacity' && 'mAh'}
                                    {currentStep === 5 && field.id === 'cRating' && 'C-Rating'}
                                    {currentStep === 5 && field.id === 'voltage' && 'Voltage'}
                                    {currentStep === 5 && field.id === 'weight' && 'Weight (g)'}
                                  </div>
                                </motion.div>
                          ))}
                        </div>
                      </div>

                          {/* Info text - moved to bottom */}
                          <div className="mt-3 pt-3 border-t border-white/5">
                            <p className="flex items-center gap-2 text-xs text-white/40">
                              <span className="text-[#00F135]">•</span>
                              All measurements in metric (mm, g)
                            </p>
                  </div>
                </div>
                    </motion.div>
                  </motion.div>
              </div>
              )}
            </div>

            {/* Navigation with Enhanced Border */}
            <motion.div 
              className="p-6 flex flex-col gap-4 border-t border-white/5 bg-gradient-to-b from-transparent to-white/[0.02]"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              {/* Step Progress Bar */}
              <div className="w-full flex flex-col gap-2">
                {/* Progress bar */}
                <div className="relative h-1 bg-white/5 rounded-full overflow-hidden">
                  <motion.div
                    className="absolute h-full bg-[#00F135]"
                    initial={false}
                    animate={{ width: `${((currentStep + 1) / buildSteps.length) * 100}%` }}
                    transition={{ duration: 0.3 }}
                  />
                </div>

                {/* Step indicators */}
                <div className="flex justify-between items-center px-1">
                  {buildSteps.map((step, index) => {
                    const isCompleted = index < currentStep;
                    const isCurrent = index === currentStep;
                    
                    return (
                      <motion.button
                        key={step.id}
                        onClick={() => {
                          if (!isTransitioning) {
                            setCurrentStep(index);
                            setShowTutorial(false);
                            setShowComponentIntro(false);
                          }
                        }}
                        className="relative group flex flex-col items-center"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        disabled={isTransitioning}
                      >
                        {/* Step number circle */}
                        <div 
                          className={`w-8 h-8 rounded-full flex items-center justify-center border-2 transition-all duration-300
                            ${isCurrent ? 'border-[#00F135] bg-[#00F135]/20' : 
                              isCompleted ? 'border-[#00F135] bg-[#00F135]' : 
                              'border-white/20 hover:border-white/40'}`}
                        >
                          <span className={`text-sm font-medium
                            ${isCurrent ? 'text-[#00F135]' : 
                              isCompleted ? 'text-black' : 
                              'text-white/60 group-hover:text-white'}`}>
                            {index + 1}
                          </span>
                        </div>

                        {/* Step name */}
                        <div className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                          <span className={`text-xs transition-colors duration-300
                            ${isCurrent ? 'text-[#00F135]' : 
                              isCompleted ? 'text-white/80' : 
                              'text-white/40 group-hover:text-white/60'}`}>
                            {step.title}
                          </span>
                        </div>
                      </motion.button>
                    );
                  })}
                </div>
              </div>

              {/* Navigation buttons */}
              <div className="flex justify-between items-center pt-8">
            <DroneforgeButton
              variant="secondary"
              size="md"
              onClick={handleBack}
                  disabled={currentStep === 0 || isTransitioning}
              className="rounded-full"
            >
              <span className="flex items-center gap-2">
                <HiOutlineArrowLeft />
                <span>Back</span>
              </span>
            </DroneforgeButton>

            <DroneforgeButton
              variant="accent"
              size="md"
              onClick={handleNext}
                  disabled={isTransitioning}
              className="rounded-full"
            >
              <span className="flex items-center gap-2">
                <span>{currentStep === buildSteps.length - 1 ? 'Finish' : 'Next'}</span>
                <HiOutlineArrowUpRight />
              </span>
            </DroneforgeButton>
          </div>
            </motion.div>
          </motion.div>
        </div>

        {/* Background Glow Effects */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <motion.div 
            className="absolute top-[15%] left-[25%] w-[50%] h-[50%] bg-[#00F135]/[0.02] blur-[100px] rounded-full"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.02, 0.04, 0.02]
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/20 via-transparent to-black/20" />
        </div>

        {/* Tutorial Modal */}
        <AnimatePresence>
          {showTutorial && tutorialContent[buildSteps[currentStep].id as keyof typeof tutorialContent] && (
            <motion.div
              className="absolute inset-0 z-50 flex items-center justify-center bg-black/90 backdrop-blur-xl"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <motion.div
                className="relative bg-white/[0.03] backdrop-blur-sm rounded-2xl p-8 max-w-2xl w-full mx-4 border border-white/5"
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.3 }}
              >
                {/* Progress indicator */}
                <div className="absolute top-0 left-0 w-full h-1 bg-white/5 rounded-t-2xl overflow-hidden">
                  <motion.div
                    className="h-full bg-gradient-to-r from-[#00F135]/80 to-[#00F135]"
                    initial={{ width: 0 }}
                    animate={{ 
                      width: `${((tutorialStep + 1) / tutorialContent[buildSteps[currentStep].id as keyof typeof tutorialContent].length) * 100}%` 
                    }}
                    transition={{ duration: 0.3 }}
                  />
                </div>

                <motion.div
                  key={`${currentStep}-${tutorialStep}`}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{ duration: 0.3 }}
                  className="text-center pt-6"
                >
                  {/* Icon */}
                  <motion.div
                    className="text-4xl mb-4"
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    {tutorialContent[buildSteps[currentStep].id as keyof typeof tutorialContent][tutorialStep].icon}
          </motion.div>

                  <h3 className="text-[#00F135] text-2xl font-medium mb-4">
                    {tutorialContent[buildSteps[currentStep].id as keyof typeof tutorialContent][tutorialStep].title}
                  </h3>
                  
                  <p className="text-gray-300 text-lg leading-relaxed mb-8">
                    {tutorialContent[buildSteps[currentStep].id as keyof typeof tutorialContent][tutorialStep].description}
                  </p>
                  
                  <div className="flex items-center justify-center gap-4">
                    <motion.button
                      onClick={handleTutorialSkip}
                      className="px-6 py-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Skip Tutorial
                    </motion.button>
                    
                    <motion.button
                      onClick={handleTutorialNext}
                      className="px-8 py-3 rounded-xl bg-gradient-to-r from-[#00F135]/80 to-[#00F135] text-black font-medium
                               hover:from-[#00F135]/90 hover:to-[#00F135] transition-all duration-200
                               shadow-lg shadow-[#00F135]/20"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {tutorialContent[buildSteps[currentStep].id as keyof typeof tutorialContent] && 
                       tutorialStep === tutorialContent[buildSteps[currentStep].id as keyof typeof tutorialContent].length - 1 
                        ? 'Get Started' 
                        : 'Next'}
                    </motion.button>
          </div>

                  <div className="mt-6 flex justify-center gap-2">
                    {tutorialContent[buildSteps[currentStep].id as keyof typeof tutorialContent]?.map((_, index) => (
                      <motion.div
                        key={index}
                        className={`w-2 h-2 rounded-full transition-colors duration-300 ${
                          index === tutorialStep ? 'bg-[#00F135]' : 'bg-white/20'
                        }`}
                        whileHover={{ scale: 1.2 }}
                      />
                    ))}
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      <style>{`
        /* Custom scrollbar only for the form area */
        .custom-scrollbar::-webkit-scrollbar {
          width: 3px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: transparent;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.06);
          border-radius: 10px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: rgba(255, 255, 255, 0.1);
        }
        
        /* Firefox scrollbar styles */
        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: rgba(255, 255, 255, 0.06) transparent;
        }
      `}</style>
    </motion.div>
  );
}; 