import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { submitWaitlist } from '../api/api';
import { HiOutlineArrowUpRight } from 'react-icons/hi2';
import { FiX } from 'react-icons/fi';

interface WaitlistFormProps {
  onSuccess?: () => void;
}

const WaitlistForm: React.FC<WaitlistFormProps> = ({ onSuccess }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    interest: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const result = await submitWaitlist(formData);
      alert('Thank you for joining our waitlist!');
      setFormData({ firstName: '', lastName: '', email: '', interest: '' });
      onSuccess?.();
    } catch (error) {
      alert('Error submitting form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      className="bg-white p-8 rounded-3xl shadow-xl max-w-md w-full border border-gray-200 relative"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Close button */}
      <button 
        onClick={onSuccess}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors bg-gray-100 hover:bg-gray-200 rounded-full p-2"
      >
        <FiX size={18} />
      </button>

      {/* Subtle glow effect */}
      <div className="absolute top-1/4 right-1/4 w-40 h-40 rounded-full bg-[#00F135]/5 filter blur-[80px] opacity-50" />
      
      <div className="relative z-10">
        <h3 className="text-2xl font-bold mb-2 text-gray-800">Join Batch 2</h3>
        
        <form onSubmit={handleSubmit} className="space-y-5">
          <motion.div
            className="relative grid grid-cols-2 gap-4"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
          >
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="John"
                className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                required
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Doe"
                className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                required
              />
            </div>
          </motion.div>
          
          <motion.div
            className="relative"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="john.doe@example.com"
              className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
              required
            />
          </motion.div>
          
          <motion.div
            className="relative"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <label htmlFor="interest" className="block text-sm font-medium text-gray-700 mb-1">I'm interested as a...</label>
            <select
              id="interest"
              name="interest"
              value={formData.interest}
              onChange={handleInputChange}
              className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 appearance-none"
              required
            >
              <option value="" disabled>Select your interest</option>
              <option value="educator">Educator</option>
              <option value="developer">Developer</option>
              <option value="commercial">Commercial</option>
              <option value="other">Other</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none mt-6">
              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                <path d="M7 7l3 3 3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </motion.div>
          
          <motion.div
            className="relative pt-2"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-[#00F135] text-black font-medium py-3 px-4 rounded-xl hover:bg-[#00F135]/90 transition-colors flex items-center justify-center group"
            >
              <span className="mr-2">{isSubmitting ? 'Submitting...' : 'Join Waitlist'}</span>
              {!isSubmitting && (
                <HiOutlineArrowUpRight className="transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
              )}
            </button>
          </motion.div>
        </form>
      </div>
    </motion.div>
  );
};

export default WaitlistForm; 