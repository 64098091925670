import React, { useState, useRef } from 'react';
import { motion, AnimatePresence, useInView } from 'framer-motion';
import { FiSearch, FiFilter, FiPlusCircle, FiDownload, FiShare2, FiX, FiLink, FiTwitter, FiMail, FiCopy, FiCheck } from 'react-icons/fi';
import { HiOutlineArrowUpRight } from 'react-icons/hi2';
import { useAuth0 } from '@auth0/auth0-react';
import Navbar from './Navbar.tsx';
import { DroneforgeButton } from './DroneforgeButton.tsx';
import WaitlistForm from './WaitlistForm.tsx';
import { ComingSoonSection } from './forge/SharedComponents.tsx';
import { BuildYourDrone } from './forge/BuildYourDrone.tsx';
import { FaInfoCircle } from 'react-icons/fa';
import { submitAIHubContent } from '../api/api';

// Sample project data
const SAMPLE_PROJECTS = [
  {
    id: 1,
    title: "Autonomous Crop Monitoring",
    author: "DroneAI Dev",
    authorImg: "/avatars/user1.png",
    description: "A complete system for monitoring crop health with automated detection of disease and water stress.",
    image: "/projects/crop-monitoring.jpg",
    category: "Agriculture",
    likes: 128,
    comments: 24,
    date: "2 days ago"
  },
  {
    id: 2,
    title: "Urban Search & Rescue Model",
    author: "Rescue Tech",
    authorImg: "/avatars/user2.png",
    description: "Fine-tuned object detection model for identifying people in disaster areas with 98.7% accuracy.",
    image: "/projects/search-rescue.jpg",
    category: "Emergency",
    likes: 234,
    comments: 46,
    date: "1 week ago"
  },
  {
    id: 3,
    title: "Bridge Inspection Assistant",
    author: "InfraAnalytics",
    authorImg: "/avatars/user3.png",
    description: "Complete system for automated bridge inspections with crack detection and structural analysis.",
    image: "/projects/bridge-inspection.jpg",
    category: "Infrastructure",
    likes: 87,
    comments: 12,
    date: "3 days ago"
  },
  {
    id: 4,
    title: "Cinematic Tracking Module",
    author: "AerialFilm Pro",
    authorImg: "/avatars/user4.png",
    description: "Advanced AI for tracking moving subjects while maintaining perfect composition.",
    image: "/projects/cinematic-track.jpg",
    category: "Photography",
    likes: 456,
    comments: 38,
    date: "5 days ago"
  }
];

// Bounty data
const BOUNTIES = [
  {
    id: 1,
    title: "Autonomous Landing System",
    reward: 500,
    deadline: "2024-05-01",
    difficulty: "Advanced",
    description: "Develop a robust autonomous landing system that can identify and land on various surfaces safely. Must handle different lighting conditions and surface types.",
    requirements: [
      "99.9% landing success rate",
      "Support for at least 5 different surface types",
      "Real-time surface analysis",
      "Emergency abort capability"
    ],
    submissions: 0,
    status: "Open",
    image: "/theforge_pics/bounty_1.png"
  },
  {
    id: 2,
    title: "Object Tracking & Following",
    reward: 500,
    deadline: "2024-04-15",
    difficulty: "Intermediate",
    description: "Create an AI model that can reliably track and follow moving objects while maintaining safe distance and avoiding obstacles in real-time.",
    requirements: [
      "Track objects at 30+ FPS",
      "Handle object occlusion",
      "Dynamic speed adjustment",
      "Multi-object priority system"
    ],
    submissions: 0,
    status: "Open",
    image: "/theforge_pics/bounty_2.png"
  },
  {
    id: 3,
    title: "Terrain Mapping & Analysis",
    reward: 500,
    deadline: "2024-06-01",
    difficulty: "Intermediate",
    description: "Build an AI system that can create detailed 3D terrain maps and identify safe flight paths, landing zones, and potential hazards in real-time.",
    requirements: [
      "Real-time 3D reconstruction",
      "Hazard classification",
      "Safe path recommendation",
      "Support for varying terrain types"
    ],
    submissions: 0,
    status: "Open",
    image: "/theforge_pics/bounty_3.png"
  }
];

// Community Projects data
const COMMUNITY_PROJECTS = [
  {
    id: 1,
    title: "Sub 15 Millisecond Object Bounding Box Tracking",
    author: "chester",
    authorImg: "/chester_profile.jpg",
    description: "Ultra-fast object tracking system optimized for drone hardware, capable of maintaining consistent bounding boxes at high frame rates.",
    image: "/theforge_pics/project1.png",
    category: "Computer Vision",
    status: "Active",
    lastUpdate: "2 days ago",
    techStack: ["Python", "TensorFlow", "CUDA", "OpenCV"],
    projectUrl: "https://x.com/chesterzelaya/status/1837639126591721583"
  },
  {
    id: 2,
    title: "Real Time Depth Estimation from Monocular Video",
    author: "chester",
    authorImg: "/chester_profile.jpg",
    description: "Single-camera depth perception solution for drones that generates accurate depth maps from standard video without requiring specialized hardware.",
    image: "/theforge_pics/project2.png",
    category: "Computer Vision",
    status: "Active",
    lastUpdate: "5 days ago",
    techStack: ["Python", "PyTorch", "Computer Vision", "SLAM"],
    projectUrl: "https://x.com/chesterzelaya/status/1872837796819550721"
  },
  {
    id: 3,
    title: "Reasoning with Drones",
    author: "chester",
    authorImg: "/chester_profile.jpg",
    description: "Advanced reasoning system that enables drones to make complex decisions based on visual inputs and environmental conditions in real-time.",
    image: "/theforge_pics/project3.png",
    category: "AI",
    status: "Active",
    lastUpdate: "1 week ago",
    techStack: ["Python", "LLMs", "Computer Vision", "Reinforcement Learning"],
    projectUrl: "https://x.com/chesterzelaya/status/1892712851766444530"
  }
];

// Updated AI Models data
const AI_MODELS = [
  {
    id: 1,
    name: "YOLOv10n",
    version: "1.0",
    description: "Real-time end-to-end object detection model optimized for efficiency. Capable of detecting and tracking multiple objects in real-time on resource-constrained devices.",
    size: "7.5 MB",
    framework: "ONNX",
    accuracy: "High",
    category: "Object Detection",
    downloads: 85,
    huggingFaceUrl: "https://huggingface.co/onnx-community/yolov10n",
    features: [
      "Real-time object detection",
      "Multi-object tracking",
      "Low latency inference",
      "Optimized for edge devices"
    ]
  },
  {
    id: 2,
    name: "Depth Anything V2 Small",
    version: "2.0",
    description: "State-of-the-art monocular depth estimation model. Generates high-quality depth maps from single RGB images without requiring specialized hardware.",
    size: "21 MB",
    framework: "ONNX",
    accuracy: "High",
    category: "Depth Estimation",
    downloads: 1152,
    huggingFaceUrl: "https://huggingface.co/onnx-community/depth-anything-v2-small",
    features: [
      "Monocular depth estimation",
      "Real-time performance",
      "High-quality depth maps",
      "WebGPU compatible"
    ]
  }
];

// Share Modal Component
const ShareModal = ({ isOpen, onClose, project }) => {
  const [copied, setCopied] = useState(false);
  
  const shareLink = `https://thedroneforge.com/project/${project.id}`;
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  
  const shareViaTwitter = () => {
    const text = `Check out this awesome drone AI project: ${project.title} on DroneForge`;
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(shareLink)}`;
    window.open(url, '_blank');
  };
  
  const shareViaEmail = () => {
    const subject = `Check out this DroneForge AI project: ${project.title}`;
    const body = `I found this interesting drone AI project on DroneForge and thought you might like it:\n\n${project.title}\n${shareLink}`;
    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };
  
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div 
          className="fixed inset-0 flex items-center justify-center z-50 p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {/* Backdrop */}
          <motion.div 
            className="absolute inset-0 bg-black/80 backdrop-blur-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          
          {/* Modal */}
          <motion.div 
            className="bg-white rounded-3xl p-6 w-full max-w-md relative z-10 shadow-xl"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ 
              duration: 0.4, 
              ease: [0.22, 1, 0.36, 1]
            }}
          >
            {/* Close button */}
            <button 
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors"
            >
              <FiX size={20} />
            </button>
            
            <h3 className="text-2xl font-bold text-gray-800 mb-2">Share Project</h3>
            <p className="text-gray-600 text-sm mb-6">
              Share "{project.title}" with others
            </p>
            
            {/* Share options */}
            <div className="space-y-4">
              {/* Copy link */}
              <button 
                onClick={copyToClipboard}
                className="flex items-center w-full bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-xl p-4 text-gray-800 transition-colors"
              >
                <div className="bg-[#00F135]/20 rounded-full p-2 mr-3">
                  {copied ? <FiCheck className="text-[#00F135]" /> : <FiCopy className="text-[#00F135]" />}
                </div>
                <span>
                  {copied ? "Link copied!" : "Copy link to clipboard"}
                </span>
              </button>
              
              {/* Twitter */}
              <button 
                onClick={shareViaTwitter}
                className="flex items-center w-full bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-xl p-4 text-gray-800 transition-colors"
              >
                <div className="bg-[#00F135]/20 rounded-full p-2 mr-3">
                  <FiTwitter className="text-[#00F135]" />
                </div>
                <span>Share on Twitter</span>
              </button>
              
              {/* Email */}
              <button 
                onClick={shareViaEmail}
                className="flex items-center w-full bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-xl p-4 text-gray-800 transition-colors"
              >
                <div className="bg-[#00F135]/20 rounded-full p-2 mr-3">
                  <FiMail className="text-[#00F135]" />
                </div>
                <span>Share via Email</span>
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

// Project Card Component
const ProjectCard = ({ project }) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(cardRef, { 
    once: true, 
    amount: 0.2,
    margin: "0px 0px -100px 0px"
  });
  
  return (
    <motion.div 
      ref={cardRef}
      className="relative rounded-3xl overflow-hidden border border-gray-300/30 bg-white h-[520px] group shadow-md hover:shadow-xl transition-all duration-300"
      style={{
        opacity: isInView ? 1 : 0,
        transform: isInView ? "none" : "translateY(20px)",
        transition: "opacity 0.5s ease, transform 0.5s ease, box-shadow 0.3s ease",
        transformPerspective: 1200,
      }}
      initial={{ rotateX: 0, rotateY: 0 }}
      whileHover={{
        rotateX: -2,
        rotateY: 2,
        y: -8,
        transition: { duration: 0.3 }
      }}
    >
      {/* Image container */}
      <div className="h-[50%] relative overflow-hidden">
        <img 
          src={project.image || "/placeholder-project.jpg"} 
          alt={project.title}
          className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-[1.05]"
        />
        <div className="absolute top-3 right-3 bg-black/60 backdrop-blur-sm text-xs font-medium text-white px-2 py-1 rounded-full">
          {project.category}
        </div>
        
        {/* Hover shine effect */}
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 z-10 transition-opacity duration-700 ease-out overflow-hidden">
          <div className="absolute top-0 left-0 h-[200%] w-[80%] bg-gradient-to-r from-transparent via-white/20 to-transparent skew-x-[30deg] transform -translate-x-full group-hover:translate-x-[200%] transition-transform duration-1500 ease-out" />
        </div>
        
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
      </div>
      
      {/* Content */}
      <div className="p-6 h-[50%] flex flex-col">
        <div className="flex items-center mb-3">
          <img 
            src={project.authorImg || "/avatars/default.png"} 
            alt={project.author}
            className="w-8 h-8 rounded-full border border-[#00F135]/30"
          />
          <div className="ml-2">
            <div className="text-gray-800 text-sm font-medium">{project.author}</div>
            <div className="text-gray-500 text-xs">{project.date}</div>
          </div>
        </div>
        
        <h3 className="text-xl font-bold text-gray-800 mb-3 group-hover:text-[#00F135] transition-colors">{project.title}</h3>
        <p className="text-gray-600 text-sm flex-grow line-clamp-4">{project.description}</p>
        
        <div className="flex justify-between items-center mt-4">
          <div className="flex space-x-3">
            <span className="text-xs text-gray-500 flex items-center gap-1">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 text-[#00F135]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
              </svg>
              {project.likes}
            </span>
            <span className="text-xs text-gray-500 flex items-center gap-1">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 text-[#00F135]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
              </svg>
              {project.comments}
            </span>
          </div>
          
          <div className="flex space-x-2">
            <motion.button 
              className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShareModalOpen(true)}
            >
              <FiShare2 className="text-gray-600 group-hover:text-[#00F135] transition-colors" size={16} />
            </motion.button>
            <motion.button 
              className="p-2 rounded-full bg-[#00F135]/10 hover:bg-[#00F135]/20 transition-colors"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <FiDownload className="text-[#00F135]" size={16} />
            </motion.button>
          </div>
        </div>
      </div>
      
      {/* Card glow effect on hover */}
      <div className="absolute inset-0 -z-10 bg-[#00F135]/0 group-hover:bg-[#00F135]/5 rounded-3xl transition-colors duration-300" />
      
      <ShareModal 
        isOpen={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        project={project}
      />
    </motion.div>
  );
};

// Model Card Component
const ModelCard = ({ model }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(cardRef, { 
    once: true, 
    amount: 0.2,
    margin: "0px 0px -100px 0px"
  });
  
  const handleDownload = () => {
    window.open(model.huggingFaceUrl, '_blank');
  };
  
  return (
    <motion.div 
      ref={cardRef}
      className="bg-white rounded-3xl border border-gray-300/30 p-6 shadow-md hover:shadow-xl transition-all duration-300"
      style={{
        opacity: isInView ? 1 : 0,
        transform: isInView ? "none" : "translateY(20px)",
        transition: "opacity 0.5s ease, transform 0.5s ease, box-shadow 0.3s ease",
      }}
      whileHover={{ y: -8 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex justify-between items-start mb-3">
        <h3 className="text-xl font-bold text-gray-800 group-hover:text-[#00F135] transition-colors">{model.name}</h3>
        <span className="bg-[#00F135]/20 text-[#00F135] text-xs font-medium px-2 py-1 rounded-full">
          {model.category}
        </span>
      </div>
      
      <p className="text-gray-600 text-sm mb-6">{model.description}</p>
      
      <div className="flex justify-between items-center">
        <div className="text-gray-500 text-xs">
          Size: {model.size} • {model.downloads.toLocaleString()} downloads
        </div>
        <DroneforgeButton
          variant="accent"
          size="sm"
          className="rounded-full group overflow-hidden relative"
          onClick={handleDownload}
        >
          <span className="relative z-10 flex items-center gap-1">
            <FiDownload size={14} />
            <span>Download</span>
          </span>
        </DroneforgeButton>
      </div>
      
      {/* Card glow effect on hover */}
      <div className="absolute inset-0 -z-10 bg-[#00F135]/0 group-hover:bg-[#00F135]/5 rounded-3xl transition-colors duration-300" />
    </motion.div>
  );
};

// Bounty Card Component
const BountyCard = ({ bounty, onSubmitSolution = () => {} }) => {
  const [showInfo, setShowInfo] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(cardRef, { 
    once: true, 
    amount: 0.2,
    margin: "0px 0px -100px 0px"
  });

  const handleInfoButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
  };

  const handleBackButtonClick = () => {
    setShowInfo(false);
  };

  return (
    <div className="relative w-full h-[520px] perspective-1000">
      <motion.div 
        ref={cardRef}
        className="relative w-full h-full rounded-2xl overflow-hidden shadow-lg group transform-gpu backface-hidden"
        style={{
          opacity: isInView ? 1 : 0,
          transform: isInView ? "none" : "translateY(20px)",
          transition: "opacity 0.5s ease, transform 0.5s ease, box-shadow 0.3s ease",
          transformStyle: "preserve-3d",
        }}
        initial={{ rotateX: 0, rotateY: 0 }}
        whileHover={{
          rotateX: -2,
          rotateY: 2,
          y: -8,
          transition: { duration: 0.3 }
        }}
      >
        <div className="absolute inset-0 w-full h-full rounded-2xl overflow-hidden">
          {/* Info button */}
          <button 
            onClick={handleInfoButtonClick}
            className="absolute top-3 right-3 w-10 h-10 rounded-full bg-white/90 flex items-center justify-center transition-transform hover:scale-110 z-30"
            aria-label="View details"
          >
            <FaInfoCircle className="w-5 h-5 text-gray-700" />
          </button>
          
          {/* Card background */}
          <div className={`absolute inset-0 ${showInfo ? 'filter blur-md' : ''} transition-all duration-300`}>
            {/* Full card image background */}
            <div className="absolute inset-0 overflow-hidden rounded-2xl">
              <img 
                src={bounty.image || "/placeholder-bounty.jpg"} 
                alt={bounty.title}
                className={`w-full h-full object-cover transition-transform duration-700 ${showInfo ? 'scale-[1.05]' : 'group-hover:scale-[1.02]'}`}
              />
              
              {/* Gradient overlay for text readability */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent" />
              
              {/* Hover shine effect */}
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 z-10 transition-opacity duration-700 ease-out overflow-hidden">
                <div className="absolute top-0 left-0 h-[200%] w-[80%] bg-gradient-to-r from-transparent via-white/20 to-transparent skew-x-[30deg] transform -translate-x-full group-hover:translate-x-[200%] transition-transform duration-1500 ease-out" />
              </div>
            </div>

            {/* Content area */}
            <div className={`absolute bottom-0 left-0 right-0 z-20 ${showInfo ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 rounded-b-2xl overflow-hidden`}>
              <div className="relative">
                {/* Glass effect background */}
                <div className="absolute inset-0 bg-white/15 backdrop-blur-[2px] transition-all duration-300 group-hover:bg-white/25" />
                
                <div className="relative z-10 p-6">
                  <div className="flex flex-col gap-4">
                    <div className="flex items-start justify-between">
                      <div>
                        <span className="inline-block bg-black/60 backdrop-blur-sm text-white px-3 py-1 rounded-full text-xs font-medium mb-3">
                          {bounty.difficulty}
                        </span>
                        <h3 className="text-2xl font-bold text-white group-hover:text-[#00F135] transition-colors">{bounty.title}</h3>
                      </div>
                      <span className="text-lg font-bold text-[#00F135]">
                        ${bounty.reward.toLocaleString()}
                      </span>
                    </div>
                    
                    <p className="text-white/90 text-sm line-clamp-2">{bounty.description}</p>
                    
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-sm text-white/70">
                        {bounty.submissions} submissions
                      </span>
                      <DroneforgeButton
                        variant="accent"
                        size="sm"
                        className="rounded-full"
                        onClick={() => onSubmitSolution()}
                      >
                        <span className="flex items-center gap-2">
                          Submit Solution
                          <HiOutlineArrowUpRight />
                        </span>
                      </DroneforgeButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Info overlay */}
          {showInfo && (
            <motion.div 
              className="absolute inset-0 z-20 bg-black/70 backdrop-blur-sm p-6 flex flex-col rounded-2xl"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex justify-between items-start mb-6">
                <motion.h2 
                  className="text-2xl font-bold text-[#00F135]"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1, duration: 0.4 }}
                >
                  {bounty.title}
                </motion.h2>
                <button
                  onClick={handleBackButtonClick}
                  className="text-white/70 hover:text-white transition-colors"
                >
                  <FiX size={24} />
                </button>
              </div>
              
              <motion.div 
                className="space-y-6 flex-grow"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.4 }}
              >
                <div>
                  <h4 className="text-sm font-bold text-white/90 mb-3">Requirements</h4>
                  <ul className="space-y-2">
                    {bounty.requirements.map((req, index) => (
                      <li key={index} className="flex items-start gap-2 text-sm text-white/80">
                        <span className="w-1.5 h-1.5 bg-[#00F135] rounded-full mt-1.5"></span>
                        {req}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="space-y-2 text-sm">
                  <p className="text-white/80"><span className="text-white/90 font-medium">Difficulty:</span> {bounty.difficulty}</p>
                  <p className="text-white/80"><span className="text-white/90 font-medium">Deadline:</span> {new Date(bounty.deadline).toLocaleDateString()}</p>
                  <p className="text-white/80"><span className="text-white/90 font-medium">Status:</span> {bounty.status}</p>
                  <p className="text-white/80"><span className="text-white/90 font-medium">Reward:</span> ${bounty.reward.toLocaleString()}</p>
                </div>
              </motion.div>

              <DroneforgeButton
                variant="accent"
                size="md"
                className="w-full mt-6 rounded-xl"
                onClick={() => {
                  handleBackButtonClick();
                  onSubmitSolution();
                }}
              >
                <span className="flex items-center justify-center gap-2">
                  Submit Solution
                  <HiOutlineArrowUpRight />
                </span>
              </DroneforgeButton>
            </motion.div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

// Community Project Card Component - Updated to match bounty card style
const CommunityProjectCard = ({ project }) => {
  const [showInfo, setShowInfo] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(cardRef, { 
    once: true, 
    amount: 0.2,
    margin: "0px 0px -100px 0px"
  });
  
  const handleViewProject = () => {
    if (project.projectUrl) {
      window.open(project.projectUrl, '_blank');
    }
  };

  const handleInfoButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
  };

  const handleBackButtonClick = () => {
    setShowInfo(false);
  };

  return (
    <div className="relative w-full h-[520px] perspective-1000">
      <motion.div 
        ref={cardRef}
        className="relative w-full h-full rounded-2xl overflow-hidden shadow-lg group transform-gpu backface-hidden"
        style={{
          opacity: isInView ? 1 : 0,
          transform: isInView ? "none" : "translateY(20px)",
          transition: "opacity 0.5s ease, transform 0.5s ease, box-shadow 0.3s ease",
          transformStyle: "preserve-3d",
        }}
        initial={{ rotateX: 0, rotateY: 0 }}
        whileHover={{
          rotateX: -2,
          rotateY: 2,
          y: -8,
          transition: { duration: 0.3 }
        }}
      >
        <div className="absolute inset-0 w-full h-full rounded-2xl overflow-hidden">
          {/* Info button */}
          <button 
            onClick={handleInfoButtonClick}
            className="absolute top-3 right-3 w-10 h-10 rounded-full bg-white/90 flex items-center justify-center transition-transform hover:scale-110 z-30"
            aria-label="View details"
          >
            <FaInfoCircle className="w-5 h-5 text-gray-700" />
          </button>
          
          {/* Card background */}
          <div className={`absolute inset-0 ${showInfo ? 'filter blur-md' : ''} transition-all duration-300`}>
            {/* Full card image background */}
            <div className="absolute inset-0 overflow-hidden rounded-2xl">
              <img 
                src={project.image || "/placeholder-project.jpg"} 
                alt={project.title}
                className={`w-full h-full object-cover transition-transform duration-700 ${showInfo ? 'scale-[1.05]' : 'group-hover:scale-[1.02]'}`}
              />
              
              {/* Gradient overlay for text readability */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent" />
              
              {/* Hover shine effect */}
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 z-10 transition-opacity duration-700 ease-out overflow-hidden">
                <div className="absolute top-0 left-0 h-[200%] w-[80%] bg-gradient-to-r from-transparent via-white/20 to-transparent skew-x-[30deg] transform -translate-x-full group-hover:translate-x-[200%] transition-transform duration-1500 ease-out" />
              </div>
            </div>

            {/* Content area */}
            <div className={`absolute bottom-0 left-0 right-0 z-20 ${showInfo ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 rounded-b-2xl overflow-hidden`}>
              <div className="relative">
                {/* Glass effect background */}
                <div className="absolute inset-0 bg-white/15 backdrop-blur-[2px] transition-all duration-300 group-hover:bg-white/25" />
                
                <div className="relative z-10 p-6">
                  <div className="flex flex-col gap-4">
                    <div className="flex items-start justify-between">
                      <div>
                        <span className="inline-block bg-black/60 backdrop-blur-sm text-white px-3 py-1 rounded-full text-xs font-medium mb-3">
                          {project.category}
                        </span>
                        <h3 className="text-2xl font-bold text-white group-hover:text-[#00F135] transition-colors">{project.title}</h3>
                      </div>
                      <div className="flex items-center gap-2">
                        <img 
                          src={project.authorImg || "/avatars/default.png"} 
                          alt={project.author}
                          className="w-6 h-6 rounded-full border border-[#00F135]/30"
                        />
                        <span className="text-sm font-bold text-white/90">
                          {project.author}
                        </span>
                      </div>
                    </div>
                    
                    <p className="text-white/90 text-sm line-clamp-2">{project.description}</p>
                    
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-sm text-white/70">
                        {project.status} • {project.lastUpdate}
                      </span>
                      <DroneforgeButton
                        variant="accent"
                        size="sm"
                        className="rounded-full"
                        onClick={() => handleViewProject()}
                      >
                        <span className="flex items-center gap-2">
                          View Project
                          <HiOutlineArrowUpRight />
                        </span>
                      </DroneforgeButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Info overlay */}
          {showInfo && (
            <motion.div 
              className="absolute inset-0 z-20 bg-black/70 backdrop-blur-sm p-6 flex flex-col rounded-2xl"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex justify-between items-start mb-6">
                <motion.h2 
                  className="text-2xl font-bold text-[#00F135]"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1, duration: 0.4 }}
                >
                  {project.title}
                </motion.h2>
                <button
                  onClick={handleBackButtonClick}
                  className="text-white/70 hover:text-white transition-colors"
                >
                  <FiX size={24} />
                </button>
              </div>
              
              <motion.div 
                className="space-y-6 flex-grow"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.4 }}
              >
                <div>
                  <h4 className="text-sm font-bold text-white/90 mb-3">Tech Stack</h4>
                  <div className="flex flex-wrap gap-2">
                    {project.techStack.map((tech, index) => (
                      <span key={index} className="text-xs bg-[#00F135]/20 text-[#00F135] px-3 py-1 rounded-full">
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="space-y-2 text-sm">
                  <p className="text-white/80">{project.description}</p>
                </div>
              </motion.div>

              <DroneforgeButton
                variant="accent"
                size="md"
                className="w-full mt-6 rounded-xl"
                onClick={() => {
                  handleBackButtonClick();
                  handleViewProject();
                }}
              >
                <span className="flex items-center justify-center gap-2">
                  View Project
                  <HiOutlineArrowUpRight />
                </span>
              </DroneforgeButton>
            </motion.div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

// AIHubForm Component for submitting new content
const AIHubForm = ({ isOpen, onClose, contentType }) => {
  // Base form structure for all types
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    email: '',
    name: '',
    // These fields will be conditionally used based on contentType
    category: '',
    requirements: '',
    reward: '',
    difficulty: '',
    deadline: '',
    techStack: '',
    framework: '',
    size: '',
    features: '',
    version: '',
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Add contentType to formData
    const dataToSubmit = {
      ...formData,
      contentType,
      submittedAt: new Date().toISOString()
    };
    
    try {
      const result = await submitAIHubContent(dataToSubmit);
      alert('Thank you for your submission!');
      onClose();
    } catch (error) {
      alert('Error submitting form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Form title based on content type
  const getFormTitle = () => {
    switch(contentType) {
      case 'bounty': return 'Submit a Bounty';
      case 'project': return 'Add a Project';
      case 'model': return 'Submit an AI Model';
      default: return 'Submit Content';
    }
  };
  
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div 
          className="fixed inset-0 flex items-center justify-center z-50 p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {/* Backdrop */}
          <motion.div 
            className="absolute inset-0 bg-black/80 backdrop-blur-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          
          {/* Modal */}
          <motion.div 
            className="bg-white p-8 rounded-3xl shadow-xl max-w-md w-full border border-gray-200 relative overflow-y-auto max-h-[90vh]"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {/* Close button */}
            <button 
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors bg-gray-100 hover:bg-gray-200 rounded-full p-2 z-10"
            >
              <FiX size={18} />
            </button>

            {/* Subtle glow effect */}
            <div className="absolute top-1/4 right-1/4 w-40 h-40 rounded-full bg-[#00F135]/5 filter blur-[80px] opacity-50" />
            
            <div className="relative z-10">
              <h3 className="text-2xl font-bold mb-4 text-gray-800">{getFormTitle()}</h3>
              
              <form onSubmit={handleSubmit} className="space-y-5">
                {/* Common fields for all content types */}
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Your Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="John Doe"
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Your Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="john.doe@example.com"
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                    {contentType === 'model' ? 'Model Name' : 'Title'}
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    placeholder={contentType === 'bounty' ? "e.g. Autonomous Landing System" : 
                                contentType === 'project' ? "e.g. Real-time Object Tracking" : 
                                "e.g. YOLOv10n"}
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                  <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Detailed description of your submission..."
                    rows={4}
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">Category</label>
                  <select
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 appearance-none"
                    required
                  >
                    <option value="" disabled>Select a category</option>
                    {contentType === 'bounty' && (
                      <>
                        <option value="Computer Vision">Computer Vision</option>
                        <option value="Navigation">Navigation</option>
                        <option value="Planning">Planning</option>
                        <option value="Control">Control</option>
                        <option value="Other">Other</option>
                      </>
                    )}
                    {contentType === 'project' && (
                      <>
                        <option value="Computer Vision">Computer Vision</option>
                        <option value="AI">AI</option>
                        <option value="Robotics">Robotics</option>
                        <option value="Education">Education</option>
                        <option value="Other">Other</option>
                      </>
                    )}
                    {contentType === 'model' && (
                      <>
                        <option value="Object Detection">Object Detection</option>
                        <option value="Depth Estimation">Depth Estimation</option>
                        <option value="Segmentation">Segmentation</option>
                        <option value="Pose Estimation">Pose Estimation</option>
                        <option value="Other">Other</option>
                      </>
                    )}
                  </select>
                </div>
                
                {/* Bounty-specific fields */}
                {contentType === 'bounty' && (
                  <>
                    <div>
                      <label htmlFor="reward" className="block text-sm font-medium text-gray-700 mb-1">Reward Amount ($)</label>
                      <input
                        type="number"
                        id="reward"
                        name="reward"
                        value={formData.reward}
                        onChange={handleInputChange}
                        placeholder="e.g. 500"
                        className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                        required
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="difficulty" className="block text-sm font-medium text-gray-700 mb-1">Difficulty Level</label>
                      <select
                        id="difficulty"
                        name="difficulty"
                        value={formData.difficulty}
                        onChange={handleInputChange}
                        className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 appearance-none"
                        required
                      >
                        <option value="" disabled>Select difficulty</option>
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Advanced">Advanced</option>
                        <option value="Expert">Expert</option>
                      </select>
                    </div>
                    
                    <div>
                      <label htmlFor="deadline" className="block text-sm font-medium text-gray-700 mb-1">Deadline</label>
                      <input
                        type="date"
                        id="deadline"
                        name="deadline"
                        value={formData.deadline}
                        onChange={handleInputChange}
                        className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800"
                        required
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="requirements" className="block text-sm font-medium text-gray-700 mb-1">Requirements (one per line)</label>
                      <textarea
                        id="requirements"
                        name="requirements"
                        value={formData.requirements}
                        onChange={handleInputChange}
                        placeholder="List requirements, one per line"
                        rows={4}
                        className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                        required
                      />
                    </div>
                  </>
                )}
                
                {/* Project-specific fields */}
                {contentType === 'project' && (
                  <div>
                    <label htmlFor="techStack" className="block text-sm font-medium text-gray-700 mb-1">Tech Stack (comma separated)</label>
                    <input
                      type="text"
                      id="techStack"
                      name="techStack"
                      value={formData.techStack}
                      onChange={handleInputChange}
                      placeholder="e.g. Python, TensorFlow, CUDA, OpenCV"
                      className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                      required
                    />
                  </div>
                )}
                
                {/* Model-specific fields */}
                {contentType === 'model' && (
                  <>
                    <div>
                      <label htmlFor="version" className="block text-sm font-medium text-gray-700 mb-1">Version</label>
                      <input
                        type="text"
                        id="version"
                        name="version"
                        value={formData.version}
                        onChange={handleInputChange}
                        placeholder="e.g. 1.0"
                        className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                        required
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="framework" className="block text-sm font-medium text-gray-700 mb-1">Framework</label>
                      <input
                        type="text"
                        id="framework"
                        name="framework"
                        value={formData.framework}
                        onChange={handleInputChange}
                        placeholder="e.g. PyTorch, TensorFlow, ONNX"
                        className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                        required
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="size" className="block text-sm font-medium text-gray-700 mb-1">Model Size</label>
                      <input
                        type="text"
                        id="size"
                        name="size"
                        value={formData.size}
                        onChange={handleInputChange}
                        placeholder="e.g. 48 MB"
                        className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                        required
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="features" className="block text-sm font-medium text-gray-700 mb-1">Features (one per line)</label>
                      <textarea
                        id="features"
                        name="features"
                        value={formData.features}
                        onChange={handleInputChange}
                        placeholder="List features, one per line"
                        rows={4}
                        className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                        required
                      />
                    </div>
                  </>
                )}
                
                {/* Submit button */}
                <div className="pt-2">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-[#00F135] text-black font-medium py-3 px-4 rounded-xl hover:bg-[#00F135]/90 transition-colors flex items-center justify-center group"
                  >
                    <span className="mr-2">{isSubmitting ? 'Submitting...' : 'Submit'}</span>
                    {!isSubmitting && (
                      <HiOutlineArrowUpRight className="transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

// BountySolutionForm Component for submitting solutions to bounties
const BountySolutionForm = ({ isOpen, onClose, bounty }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    solutionTitle: '',
    description: '',
    repoUrl: '',
    approach: '',
    additionalNotes: ''
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Prepare data for submission
    const dataToSubmit = {
      ...formData,
      contentType: 'bounty-solution',
      bountyId: bounty.id,
      bountyTitle: bounty.title,
      submittedAt: new Date().toISOString()
    };
    
    try {
      const result = await submitAIHubContent(dataToSubmit);
      alert('Thank you for your solution submission!');
      onClose();
    } catch (error) {
      alert('Error submitting solution. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div 
          className="fixed inset-0 flex items-center justify-center z-50 p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {/* Backdrop */}
          <motion.div 
            className="absolute inset-0 bg-black/80 backdrop-blur-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          
          {/* Modal */}
          <motion.div 
            className="bg-white p-8 rounded-3xl shadow-xl max-w-md w-full border border-gray-200 relative overflow-y-auto max-h-[90vh]"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {/* Close button */}
            <button 
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors bg-gray-100 hover:bg-gray-200 rounded-full p-2 z-10"
            >
              <FiX size={18} />
            </button>

            {/* Subtle glow effect */}
            <div className="absolute top-1/4 right-1/4 w-40 h-40 rounded-full bg-[#00F135]/5 filter blur-[80px] opacity-50" />
            
            <div className="relative z-10">
              <h3 className="text-2xl font-bold mb-2 text-gray-800">Submit Solution</h3>
              <p className="text-gray-600 text-sm mb-4">
                For bounty: <span className="font-medium">{bounty.title}</span>
              </p>
              
              <form onSubmit={handleSubmit} className="space-y-5">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Your Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="John Doe"
                      className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                      required
                    />
                  </div>
                  
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Your Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="john.doe@example.com"
                      className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                      required
                    />
                  </div>
                </div>
                
                <div>
                  <label htmlFor="solutionTitle" className="block text-sm font-medium text-gray-700 mb-1">Solution Title</label>
                  <input
                    type="text"
                    id="solutionTitle"
                    name="solutionTitle"
                    value={formData.solutionTitle}
                    onChange={handleInputChange}
                    placeholder="A brief title for your solution"
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                  <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Describe your solution in detail..."
                    rows={3}
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="repoUrl" className="block text-sm font-medium text-gray-700 mb-1">Repository URL (Optional)</label>
                  <input
                    type="url"
                    id="repoUrl"
                    name="repoUrl"
                    value={formData.repoUrl}
                    onChange={handleInputChange}
                    placeholder="https://github.com/yourusername/your-repo"
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                  />
                </div>
                
                <div>
                  <label htmlFor="approach" className="block text-sm font-medium text-gray-700 mb-1">Your Approach</label>
                  <textarea
                    id="approach"
                    name="approach"
                    value={formData.approach}
                    onChange={handleInputChange}
                    placeholder="Explain your technical approach and methodology..."
                    rows={4}
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="additionalNotes" className="block text-sm font-medium text-gray-700 mb-1">Additional Notes (Optional)</label>
                  <textarea
                    id="additionalNotes"
                    name="additionalNotes"
                    value={formData.additionalNotes}
                    onChange={handleInputChange}
                    placeholder="Any other information you'd like to share..."
                    rows={3}
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:border-[#00F135] focus:ring-1 focus:ring-[#00F135] transition-colors text-gray-800 placeholder-gray-400"
                  />
                </div>
                
                <div className="pt-2">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-[#00F135] text-black font-medium py-3 px-4 rounded-xl hover:bg-[#00F135]/90 transition-colors flex items-center justify-center group"
                  >
                    <span className="mr-2">{isSubmitting ? 'Submitting...' : 'Submit Solution'}</span>
                    {!isSubmitting && (
                      <HiOutlineArrowUpRight className="transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

// Main AIHub Component
const AIHub = () => {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const [activeTab, setActiveTab] = useState('bounties');
  const [searchQuery, setSearchQuery] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [formContentType, setFormContentType] = useState('');
  const [showSolutionForm, setShowSolutionForm] = useState(false);
  const [selectedBounty, setSelectedBounty] = useState(null);
  
  const handleShowForm = (contentType) => {
    setFormContentType(contentType);
    setShowForm(true);
  };
  
  const handleShowSolutionForm = (bounty) => {
    setSelectedBounty(bounty);
    setShowSolutionForm(true);
  };
  
  return (
    <div className="bg-white min-h-screen relative overflow-hidden">
      <Navbar show={true} />
      
      {/* Subtle background pattern */}
      <div className="absolute inset-0 opacity-[0.06]">
        <div 
          className="h-full w-full" 
          style={{
            backgroundImage: `radial-gradient(circle, rgba(0, 241, 53, 0.5) 1px, transparent 1px)`,
            backgroundSize: '40px 40px',
          }}
        />
      </div>
      
      {/* Very subtle glow effects */}
      <div className="absolute top-1/4 left-1/4 w-96 h-96 rounded-full bg-[#00F135]/5 filter blur-[120px] opacity-[0.15]" />
      <div className="absolute bottom-1/4 right-1/4 w-80 h-80 rounded-full bg-[#00F135]/5 filter blur-[100px] opacity-[0.1]" />
      
      <div className="pt-32 pb-16 relative z-10">
        <div className="w-[95%] max-w-7xl mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            <h1 className="text-5xl md:text-6xl font-horizon text-gray-800 mb-4">
              <span className="text-black">THE</span> <span className="text-[#00F135]">FORGE</span>
            </h1>
            <p className="text-gray-600 max-w-2xl mx-auto font-montserrat text-lg">
              Discover, share, and collaborate on AI projects for drones. Access powerful pre-trained models 
              and resources for your next innovation.
            </p>
          </motion.div>

          {/* Main Content Layout */}
          <div className="w-full">
            {/* Tab Navigation */}
            <div className="flex justify-center mb-10 overflow-x-auto scrollbar-hide">
              <div className="flex gap-4 items-center">
                {/* Build Your Own Drone Button */}
                <motion.div 
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.7 }}
                >
                  <DroneforgeButton
                    variant="accent"
                    size="md"
                    className="rounded-full group overflow-hidden relative flex items-center gap-2 px-6"
                    onClick={() => setActiveTab('build')}
                  >
                    <span className="relative z-10 flex items-center gap-2">
                      <span className="font-medium">Build Your Own Drone</span>
                      <HiOutlineArrowUpRight className="transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                    </span>
                    {/* Gradient background */}
                    <div className="absolute inset-0 bg-gradient-to-r from-[#00F135] to-[#00F135]/90" />
                  </DroneforgeButton>
                </motion.div>

                {/* Main Tabs */}
                <div className="bg-white border border-gray-200 rounded-full p-1 flex shadow-sm">
                  <button 
                    className={`px-5 py-2 rounded-full text-sm font-medium transition-all ${
                      activeTab === 'bounties' ? 'bg-[#00F135] text-black shadow-sm' : 'text-gray-600 hover:text-gray-800'
                    }`}
                    onClick={() => setActiveTab('bounties')}
                  >
                    Bounties
                  </button>
                  <button 
                    className={`px-5 py-2 rounded-full text-sm font-medium transition-all ${
                      activeTab === 'projects' ? 'bg-[#00F135] text-black shadow-sm' : 'text-gray-600 hover:text-gray-800'
                    }`}
                    onClick={() => setActiveTab('projects')}
                  >
                    Community Projects
                  </button>
                  <button 
                    className={`px-5 py-2 rounded-full text-sm font-medium transition-all ${
                      activeTab === 'models' ? 'bg-[#00F135] text-black shadow-sm' : 'text-gray-600 hover:text-gray-800'
                    }`}
                    onClick={() => setActiveTab('models')}
                  >
                    AI Models
                  </button>
                </div>
              </div>
            </div>

            {/* Search and Filter Bar */}
            {activeTab !== 'build' && (
              <div className="max-w-4xl mx-auto mb-10">
                <div className="bg-white rounded-3xl p-4 border border-gray-200 shadow-sm flex flex-col md:flex-row gap-4">
                  <div className="relative flex-1">
                    <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                    <input 
                      type="text" 
                      placeholder={
                        activeTab === 'bounties' ? "Search bounties..." : 
                        activeTab === 'projects' ? "Search projects..." : 
                        "Search AI models..."
                      }
                      className="w-full bg-gray-50 border border-gray-200 rounded-lg py-2 pl-10 pr-4 text-gray-800 focus:outline-none focus:border-[#00F135] transition-colors"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <div className="flex gap-3">
                    <button className="bg-gray-50 border border-gray-200 rounded-lg px-4 py-2 text-gray-600 hover:text-gray-800 hover:border-gray-300 flex items-center gap-2 transition-colors">
                      <FiFilter size={16} />
                      <span>Filter</span>
                    </button>
                    
                    <DroneforgeButton
                      variant="accent"
                      size="md"
                      className="rounded-lg group overflow-hidden relative md:whitespace-nowrap"
                      onClick={() => handleShowForm(
                        activeTab === 'bounties' ? 'bounty' : 
                        activeTab === 'projects' ? 'project' : 
                        'model'
                      )}
                    >
                      <span className="relative z-10 flex items-center gap-2">
                        <FiPlusCircle size={16} />
                        <span>{
                          activeTab === 'bounties' ? "Submit Bounty" : 
                          activeTab === 'projects' ? "Add Project" : 
                          "Submit Model"
                        }</span>
                        <HiOutlineArrowUpRight className="transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                      </span>
                    </DroneforgeButton>
                  </div>
                </div>
              </div>
            )}

            {/* Content */}
            <div className="max-w-7xl mx-auto">
              {activeTab === 'bounties' && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {BOUNTIES.map(bounty => (
                    <BountyCard 
                      key={bounty.id} 
                      bounty={bounty} 
                      onSubmitSolution={() => handleShowSolutionForm(bounty)}
                    />
                  ))}
                </div>
              )}
              
              {activeTab === 'projects' && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {COMMUNITY_PROJECTS.map(project => (
                    <CommunityProjectCard key={project.id} project={project} />
                  ))}
                </div>
              )}

              {activeTab === 'models' && (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                  {AI_MODELS.map(model => (
                    <ModelCard key={model.id} model={model} />
                  ))}
                </div>
              )}

              {activeTab === 'build' && (
                <BuildYourDrone />
              )}
            </div>
          </div>
        </div>
      </div>
      
      {/* AIHub Form Modal */}
      <AIHubForm 
        isOpen={showForm} 
        onClose={() => setShowForm(false)} 
        contentType={formContentType} 
      />
      
      {/* Bounty Solution Form Modal */}
      {selectedBounty && (
        <BountySolutionForm
          isOpen={showSolutionForm}
          onClose={() => setShowSolutionForm(false)}
          bounty={selectedBounty}
        />
      )}
      
      {/* Custom styles */}
      <style dangerouslySetInnerHTML={{__html: `
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        @keyframes shimmer {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(100%);
          }
        }
        .animate-shimmer {
          animation: shimmer 2s infinite;
        }
        .perspective-1000 {
          perspective: 1000px;
        }
        .transform-gpu {
          transform: translate3d(0, 0, 0);
        }
        .backface-hidden {
          backface-visibility: hidden;
        }
      `}} />
    </div>
  );
};

export default AIHub; 