'use client';

import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { FaRobot, FaCamera, FaMap, FaBrain, FaInfoCircle } from "react-icons/fa";

// Export StyleFixes component for global styles
export const StyleFixes: React.FC = () => {
  useEffect(() => {
    // Add a style tag to fix any global styling issues
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      /* Fix green lines under navbar and borders */
      .border-t-[#00F135], .border-[#00F135], .border-[#00F135]/20, .border-[#00F135]/30, .border-[#00F135]/40 { 
        border-color: rgba(0,0,0,0.2) !important; 
      }
      
      /* Fix navbar border */
      nav { 
        border-bottom: none !important; 
      }
      
      /* Remove green lines behind images */
      .bg-gradient-to-r {
        background: transparent !important;
      }
      
      .h-[1px], .h-[2px], .w-[1px], .w-[2px], .w-[3px] {
        background: rgba(255,255,255,0.1) !important;
      }
    `;
    document.head.appendChild(styleTag);
    
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);
  
  return null;
};

// Main capability cards data - updated for drone AI features
const capabilityCards = [
  {
    id: "object-detection",
    title: "Object Detection",
    icon: <FaRobot className="w-6 h-6" />,
    color: "#00F135",
    imageSrc: "/webp_converted/bounding_box_cinematic.webp",
    description: "Advanced neural networks identify and track objects in real-time, enabling autonomous navigation and intelligent monitoring capabilities.",
    detailedInfo: "Nimbus's object detection leverages cutting-edge neural networks that can identify and track multiple objects simultaneously. This advanced capability enables drones to recognize vehicles, people, buildings, and custom objects in real-time, allowing for autonomous navigation, precision tracking, and intelligent decision making during missions."
  },
  {
    id: "segmentation",
    title: "Segmentation",
    icon: <FaCamera className="w-6 h-6" />,
    color: "#00F135",
    imageSrc: "/webp_converted/segmentation_cinematic.webp",
    description: "Our AI precisely segments terrain and objects for enhanced environmental understanding, allowing for optimized flight paths and detailed analytics.",
    detailedInfo: "Image segmentation technology allows Nimbus to analyze visual data at the pixel level, precisely delineating terrain features, objects, and environmental elements. This detailed understanding enables drones to generate high-resolution maps, optimize flight paths around complex structures, and provide detailed analytics for agriculture, construction, and inspection applications."
  },
  {
    id: "navigation",
    title: "Obstacle Navigation",
    icon: <FaMap className="w-6 h-6" />,
    color: "#00F135",
    imageSrc: "/webp_converted/obstacle_avoidance_cinematic.webp",
    description: "Dynamic path planning and obstacle avoidance algorithms enable the drone to navigate complex environments safely without manual control.",
    detailedInfo: "Nimbus's obstacle avoidance system combines depth sensing with predictive algorithms to create real-time 3D maps of the environment. This allows drones to detect obstacles in their flight path, calculate optimal trajectories, and safely navigate even the most complex environments autonomously, including indoor spaces and areas with dynamic obstacles."
  },
  {
    id: "reasoning",
    title: "AI Reasoning",
    icon: <FaBrain className="w-6 h-6" />,
    color: "#00F135",
    imageSrc: "/webp_converted/buffer_chatgpt.webp",
    description: "The drone's onboard AI makes intelligent decisions based on real-time data, adapting to new situations and optimizing mission parameters automatically.",
    detailedInfo: "The true power of Nimbus lies in its advanced AI reasoning capabilities. Going beyond simple detection and avoidance, Nimbus can analyze situations, prioritize mission objectives, and make intelligent decisions in real-time. This allows drones to adapt to changing conditions, optimize flight parameters based on environmental factors, and autonomously respond to unexpected events without human intervention."
  }
];

// Card component with info overlay
const CapabilityCard = ({ card }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Check if viewport is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleInfoButtonClick = (e) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
  };

  const handleBackButtonClick = () => {
    setShowInfo(false);
  };

  return (
    <div className="relative w-full md:w-[470px] lg:w-[560px] h-[520px] sm:h-[550px] md:h-[665px] rounded-2xl overflow-hidden shadow-lg">
      {/* Info button */}
      <button 
        onClick={handleInfoButtonClick}
        className="absolute top-3 sm:top-4 right-3 sm:right-4 w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-white/90 flex items-center justify-center transition-transform hover:scale-110 z-30"
        aria-label="View details"
      >
        <FaInfoCircle className="w-5 h-5 sm:w-6 sm:h-6 text-gray-700" />
      </button>
      
      {/* Card background */}
      <div className={`absolute inset-0 ${showInfo ? 'filter blur-md' : ''} transition-all duration-300`}>
        {/* Full card image background */}
        <div className="absolute inset-0 overflow-hidden">
          {card.imageSrc ? (
            <img 
              src={card.imageSrc} 
              alt={card.title}
              className={`w-full h-full object-cover transition-transform duration-700 ${showInfo ? 'scale-[1.05]' : 'hover:scale-[1.02]'}`}
            />
          ) : (
            <div className="w-full h-full bg-gray-900 flex items-center justify-center">
              <div className="w-12 h-12 sm:w-16 sm:h-16 rounded-full" style={{ backgroundColor: `${card.color}30` }}>
                <div className="w-full h-full flex items-center justify-center animate-pulse">
                  {React.cloneElement(card.icon, { className: "w-6 h-6 sm:w-8 sm:h-8", style: { color: card.color } })}
                </div>
              </div>
            </div>
          )}
          
          {/* Gradient overlay for text readability */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent"></div>
        </div>
        
        {/* Title - positioned at bottom left */}
        <div className={`absolute bottom-6 sm:bottom-8 left-6 sm:left-8 transition-opacity duration-300 ${showInfo ? 'opacity-0' : 'opacity-100'}`}>
          <h2 className="text-white text-2xl sm:text-3xl font-bold">{card.title}</h2>
        </div>
      </div>
      
      {/* Detailed info overlay */}
      {showInfo && (
        <motion.div 
          className="absolute inset-0 z-20 bg-black/70 backdrop-blur-sm p-6 sm:p-8 flex flex-col justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.h2 
            className="text-2xl sm:text-3xl font-bold text-[#00F135] mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1, duration: 0.4 }}
          >
            {card.title}
          </motion.h2>
          
          <motion.div 
            className="w-12 sm:w-16 h-1 mb-4 sm:mb-6 rounded-full"
            style={{ backgroundColor: card.color }}
            initial={{ opacity: 0, scaleX: 0 }}
            animate={{ opacity: 1, scaleX: 1 }}
            transition={{ delay: 0.15, duration: 0.5 }}
          ></motion.div>
          
          <motion.p 
            className="text-white text-base sm:text-lg leading-relaxed mb-6 overflow-y-auto max-h-[300px] sm:max-h-none scrollbar-hide"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.4 }}
          >
            {card.detailedInfo || card.description}
          </motion.p>
          
          <motion.div
            className="mt-auto flex justify-between items-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.4 }}
          >
            <div className="w-12 h-12 sm:w-16 sm:h-16 rounded-full bg-black/30 flex items-center justify-center">
              {React.cloneElement(card.icon, { className: "w-6 h-6 sm:w-8 sm:h-8", style: { color: card.color } })}
            </div>
            
            <button 
              onClick={handleBackButtonClick}
              className="px-4 sm:px-6 py-2 sm:py-3 bg-white/10 hover:bg-white/20 text-white font-medium rounded-full transition-colors duration-300"
            >
              Back to Card
            </button>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

// The main interface component with horizontal scrolling
const Interface: React.FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);

  // Detect which card is active based on scrolling
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const handleScroll = () => {
      if (isDragging) return;
      
      // Adjust card width calc for mobile
      const isMobile = window.innerWidth < 768;
      const cardWidth = isMobile 
        ? scrollContainer.clientWidth * 0.9 // 90vw
        : scrollContainer.offsetWidth > 1200 ? 560 : 470;
        
      const scrollPosition = scrollContainer.scrollLeft;
      const index = Math.round(scrollPosition / (cardWidth + (isMobile ? 16 : 20))); // Adjust gap calculation
      
      if (index >= 0 && index < capabilityCards.length && index !== activeCardIndex) {
        setActiveCardIndex(index);
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);
    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, [activeCardIndex, isDragging]);

  // Mouse drag handlers
  const handleMouseDown = (e) => {
    setIsDragging(true);
    if (scrollRef.current) {
      setStartX(e.pageX - scrollRef.current.offsetLeft);
      setScrollLeft(scrollRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !scrollRef.current) return;
    e.preventDefault();
    
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Scroll speed
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Touch handlers for mobile - improved
  const handleTouchStart = (e) => {
    if (scrollRef.current) {
      setTouchStartX(e.touches[0].clientX);
      setScrollLeft(scrollRef.current.scrollLeft);
    }
  };

  const handleTouchMove = (e) => {
    if (!scrollRef.current) return;
    const touchCurrentX = e.touches[0].clientX;
    const walk = (touchStartX - touchCurrentX) * 1.5; // Adjusted for smoother scrolling
    scrollRef.current.scrollLeft = scrollLeft + walk;
  };

  // Handle snap to nearest card on touch end
  const handleTouchEnd = () => {
    if (!scrollRef.current) return;
    
    const isMobile = window.innerWidth < 768;
    const cardWidth = isMobile 
      ? scrollRef.current.clientWidth * 0.9
      : scrollRef.current.offsetWidth > 1200 ? 560 : 470;
    
    const gap = isMobile ? 16 : 20;
    const totalWidth = cardWidth + gap;
    
    const nearestCardIndex = Math.round(scrollRef.current.scrollLeft / totalWidth);
    const targetPosition = nearestCardIndex * totalWidth;
    
    scrollRef.current.scrollTo({
      left: targetPosition,
      behavior: 'smooth'
    });
  };

  return (
    <section className="relative w-full pt-0 pb-6 bg-white overflow-hidden">
      <div className="container-fluid px-0 relative z-10">
        <div className="w-full mx-auto">
          {/* Horizontal scrolling cards container */}
          <div 
            ref={scrollRef}
            className="flex overflow-x-auto py-4 px-0 mx-auto w-[92%] md:mx-4 md:ml-[2.5%] scrollbar-hide snap-x snap-mandatory"
            style={{ 
              cursor: isDragging ? 'grabbing' : 'grab',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              WebkitOverflowScrolling: 'touch'
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div className="flex space-x-5 md:space-x-5 lg:space-x-6 pl-0 sm:pl-1 md:pl-4 pr-4">
              {capabilityCards.map((card, index) => (
                <div 
                  key={card.id}
                  className={`snap-center ${index === 0 ? 'ml-0' : ''} ${index === capabilityCards.length - 1 ? 'pr-4' : ''} min-w-[92vw] sm:min-w-[90vw] md:min-w-[470px] lg:min-w-[560px]`}
                >
                  <CapabilityCard card={card} />
                </div>
              ))}
            </div>
          </div>

          {/* Subtle scroll indicator */}
          <div className="flex justify-center mt-6 sm:mt-8 space-x-1">
            {capabilityCards.map((_, index) => (
              <div
                key={index}
                className={`h-2 rounded-full transition-all duration-300 ${
                  index === activeCardIndex 
                    ? 'bg-[#00F135] w-6 sm:w-8' 
                    : 'bg-gray-300 w-2'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
      
      {/* Custom styles */}
      <style dangerouslySetInnerHTML={{__html: `
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .perspective-1000 {
          perspective: 1000px;
        }
        .backface-hidden {
          backface-visibility: hidden;
        }
        .rotate-y-180 {
          transform: rotateY(180deg);
        }
        .transform-gpu {
          transform: translateZ(0);
        }
        
        /* iOS Safari specific overrides */
        @supports (-webkit-touch-callout: none) {
          .snap-x {
            -webkit-overflow-scrolling: touch;
            scroll-snap-type: x mandatory;
          }
          .snap-center {
            scroll-snap-align: center;
          }
        }
      `}} />
    </section>
  );
};

export default Interface;
