import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Landing_Bryce from './components/Landing_Bryce.tsx';
import Mission from './components/Mission.tsx';
import UserProfile from './components/UserProfile.tsx';
import Changelog from './components/Changelog.tsx';
import AIHub from './components/AIHub.tsx';
import { HeroSection2 } from './components/sections/HeroSection2.tsx';
import { TestBackground } from './components/sections/TestBackground.tsx';

// Basic Protected Route - only checks authentication
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname }
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black">
        <div className="text-[#00F135] text-xl font-mono">Loading...</div>
      </div>
    );
  }

  return isAuthenticated ? children : null;
};

const SimulationPage = () => {
  React.useEffect(() => {
    // Simple redirect to simulation domain
    window.location.href = 'https://simulation.thedroneforge.com';
  }, []);

  return (
    <div className="min-h-screen bg-black text-white p-8">
      <div className="max-w-4xl mx-auto text-center">
        <h1 className="text-4xl font-bold text-[#00F135] mb-4">Redirecting to Simulation...</h1>
        <p className="text-xl text-gray-300 mb-8">
          Please wait while we redirect you to the simulation environment.
        </p>
      </div>
    </div>
  );
};

function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: process.env.NODE_ENV === 'production' 
          ? 'https://thedroneforge.com' 
          : window.location.origin,
        scope: "openid profile email",
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Landing_Bryce />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/changelog" element={<Changelog />} />
          <Route path="/hero2" element={<HeroSection2 />} />
          <Route path="/test-bg" element={<TestBackground />} />
          <Route 
            path="/simulation" 
            element={
              <ProtectedRoute>
                <SimulationPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/the-forge" 
            element={<AIHub />} 
          />
          <Route 
            path="/profile" 
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            } 
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </Auth0Provider>
  );
}

export default App;