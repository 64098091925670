import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://backend-fpv-partpicker.onrender.com/api';


export const submitWaitlist = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/waitlist`, formData);
    return response.data;
  } catch (error) {
    console.error('Error submitting to waitlist:', error);
    throw error;
  }
};

export const submitAIHubContent = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/ai_hub`, formData);
    return response.data;
  } catch (error) {
    console.error('Error submitting to AI Hub:', error);
    throw error;
  }
};