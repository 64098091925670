import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { DroneforgeButton } from '../DroneforgeButton.tsx';
import { FiTwitter, FiMail } from 'react-icons/fi';
import { FaDiscord } from 'react-icons/fa';
import { HiOutlineArrowUpRight } from 'react-icons/hi2';
import WaitlistForm from '../WaitlistForm.tsx';

// Utility function to merge class names
const cn = (...classes: (string | undefined | boolean)[]) => {
  return classes.filter(Boolean).join(' ');
};

// Simplified title component without animations
interface ModernTitleProps {
  title?: string;
  subtitle?: string;
  className?: string;
  textSize?: string;
  subtitleSize?: string;
}

const ModernTitle: React.FC<ModernTitleProps> = ({
  title = "NIMBUS",
  subtitle = "The Future of Flight is Intelligent",
  className,
  textSize = "text-6xl lg:text-7xl",
  subtitleSize = "text-lg"
}) => {
  return (
    <div className={cn("relative flex flex-col items-start justify-center", className)}>
      <div className="relative z-10 flex flex-col items-start justify-center">
        <div className="mb-3">
          <p className="font-montserrat text-white/80 italic text-lg lg:text-xl">
            Introducing...
          </p>
        </div>
        <div>
          <h1 
            className={cn("font-horizon tracking-tight leading-none text-white", textSize)}
          >
            {title.substring(0, 6)}
          </h1>
        </div>
        
        <div className="relative mt-6">
          <p 
            className={cn("font-montserrat text-white/80 tracking-wide font-light", subtitleSize)}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );
};

// Video background component
const VideoBackground: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const playVideo = () => {
      // Only attempt to play after the poster has been displayed for a moment
      if (isVideoLoaded) {
        video.play().catch(() => {
          // If autoplay fails, retry after user interaction
          document.addEventListener('click', () => video.play(), { once: true });
        });
      }
    };

    // Handle loaded data
    const handleVideoLoaded = () => {
      setIsVideoLoaded(true);
    };

    // Add event listeners
    video.addEventListener('loadeddata', handleVideoLoaded);
    
    // Clean up
    return () => {
      video.removeEventListener('loadeddata', handleVideoLoaded);
    };
  }, [isVideoLoaded]);

  // Start playing after video is loaded
  useEffect(() => {
    if (isVideoLoaded && videoRef.current) {
      videoRef.current.play().catch(e => console.log("Video autoplay prevented:", e));
    }
  }, [isVideoLoaded]);

  return (
    <div className="absolute inset-0 overflow-hidden rounded-3xl">
      {/* Low-res poster image as immediate placeholder */}
      <div 
        className={`absolute inset-0 bg-cover bg-center transition-opacity duration-500 ${isVideoLoaded ? 'opacity-0' : 'opacity-100'}`}
        style={{
          backgroundImage: `url('/landing_background_poster.jpg')`,
          filter: 'brightness(0.8)'
        }}
      />

      <video
        ref={videoRef}
        autoPlay={false} // We'll handle playback manually after load
        loop
        muted
        playsInline
        preload="metadata"
        poster="/landing_background_poster.jpg"
        className="absolute w-full h-full object-cover transition-opacity duration-500"
        style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          filter: 'brightness(0.8)',
          opacity: isVideoLoaded ? 1 : 0
        }}
      >
        {/* Responsive video sources based on device/connection */}
        <source media="(max-width: 768px)" src="/landing_background_mobile.mp4" type="video/mp4" />
        <source src="/landing_background_optimized.mp4" type="video/mp4" />
      </video>
      
      {/* Darker overlay for better white text contrast */}
      <div className="absolute inset-0 bg-black/40" />
    </div>
  );
};

export const HeroSection2: React.FC = () => {
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const containerRef = useRef<HTMLDivElement>(null);
  
  // Check screen size
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Simulate loading state
  useEffect(() => {
    const timer = setTimeout(() => setIsLoaded(true), 400);
    return () => clearTimeout(timer);
  }, []);
  
  const handleContactClick = () => {
    window.location.href = 'mailto:chesterzelaya@berkeley.edu';
  };
  
  // Force white background on the body
  useEffect(() => {
    // Set body background to white
    document.body.style.backgroundColor = 'white';
    
    return () => {
      // Cleanup is only necessary if changing back
    };
  }, []);
  
  // Card component (mobile layout)
  if (isMobile) {
    return (
      <div className="relative w-full h-[90vh] p-4 bg-white flex items-center justify-center">
        <motion.div 
          ref={containerRef} 
          className="relative w-full h-[85vh] mx-auto overflow-hidden rounded-3xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          {/* Video background */}
          <VideoBackground />
          
          <AnimatePresence>
            {!isLoaded ? (
              <motion.div
                key="loader" 
                className="absolute inset-0 flex items-center justify-center z-10 bg-black/50"
                exit={{ opacity: 0 }}
              >
                <motion.div 
                  className="w-8 h-8 rounded-full bg-[#00F135]"
                  animate={{ 
                    scale: [1, 1.5, 1],
                  }}
                  transition={{ 
                    duration: 1.5, 
                    repeat: Infinity,
                    ease: "easeInOut" 
                  }}
                />
              </motion.div>
            ) : (
              <motion.div 
                key="content"
                className="relative z-10 flex flex-col justify-center items-start h-full p-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {/* Content */}
                <div className="flex flex-col">
                  {/* Title */}
                  <div className="w-full mb-6">
                    <ModernTitle 
                      textSize="text-4xl"
                      subtitleSize="text-sm"
                      subtitle="Where AI Meets the Sky"
                    />
                  </div>
                  
                  {/* CTA */}
                  <motion.div
                    className="flex flex-col items-start gap-4 w-full mt-6"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4, duration: 0.6 }}
                  >
                    <DroneforgeButton
                      variant="accent"
                      size="md"
                      className="rounded-full relative overflow-hidden group"
                      onClick={() => setShowWaitlist(true)}
                    >
                      <span className="relative z-10 flex items-center gap-2">
                        <span>JOIN WAITLIST</span>
                        <HiOutlineArrowUpRight className="transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                      </span>
                    </DroneforgeButton>
                    
                    <div className="flex gap-3 mt-2">
                      <DroneforgeButton href="https://discord.gg/xFh3xxfyUh" variant="primary" size="sm" className="relative overflow-hidden group">
                        <FaDiscord className="relative z-10" />
                      </DroneforgeButton>
                      <DroneforgeButton href="https://twitter.com/thedroneforge" variant="primary" size="sm" className="relative overflow-hidden group">
                        <FiTwitter className="relative z-10" />
                      </DroneforgeButton>
                      <DroneforgeButton variant="primary" size="sm" onClick={handleContactClick} className="relative overflow-hidden group">
                        <FiMail className="relative z-10" />
                      </DroneforgeButton>
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          
          {/* Waitlist modal */}
          {showWaitlist && (
            <motion.div
              className="fixed inset-0 z-50 flex items-center justify-center p-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <motion.div 
                className="absolute inset-0 bg-black/90 backdrop-blur-sm"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                onClick={() => setShowWaitlist(false)}
              />
              <motion.div 
                className="relative z-10"
                initial={{ scale: 0.95, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ 
                  duration: 0.4, 
                  ease: [0.22, 1, 0.36, 1]
                }}
              >
                <WaitlistForm onSuccess={() => setShowWaitlist(false)} />
              </motion.div>
            </motion.div>
          )}
        </motion.div>
      </div>
    );
  }
  
  // Desktop card layout
  return (
    <div className="relative w-full h-[90vh] bg-white flex items-center justify-center">
      <motion.div 
        ref={containerRef} 
        className="relative w-[95%] h-[85vh] mx-auto overflow-hidden rounded-3xl"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
      >
        {/* Video background */}
        <VideoBackground />
        
        <AnimatePresence>
          {!isLoaded ? (
            <motion.div
              key="loader" 
              className="absolute inset-0 flex items-center justify-center z-10 bg-black/50"
              exit={{ opacity: 0 }}
            >
              <motion.div 
                className="w-10 h-10 rounded-full bg-[#00F135]"
                animate={{ 
                  scale: [1, 1.5, 1],
                }}
                transition={{ 
                  duration: 1.5, 
                  repeat: Infinity,
                  ease: "easeInOut" 
                }}
              />
            </motion.div>
          ) : (
            <div className="relative z-10 p-12 h-full flex items-center">
              <div className="flex flex-col items-start">
                {/* Content area - without background overlay */}
                <div className="w-full max-w-2xl p-8">
                  {/* Title */}
                  <div className="mb-8">
                    <ModernTitle 
                      textSize="text-6xl lg:text-7xl"
                      subtitleSize="text-xl"
                      subtitle="Turn Any Drone into an Intelligent, Autonomous System"
                    />
                  </div>
                  
                  {/* CTA buttons */}
                  <motion.div 
                    className="flex flex-wrap items-center gap-5 mt-8"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 0.6 }}
                  >
                    <DroneforgeButton
                      variant="accent"
                      size="lg"
                      className="rounded-full group overflow-hidden relative"
                      onClick={() => setShowWaitlist(true)}
                    >
                      <span className="relative z-10 flex items-center gap-2">
                        <span>JOIN WAITLIST</span>
                        <HiOutlineArrowUpRight className="transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                      </span>
                    </DroneforgeButton>
                    
                    <div className="flex gap-4">
                      <DroneforgeButton 
                        href="https://discord.gg/xFh3xxfyUh" 
                        variant="primary" 
                        size="md" 
                        className="relative overflow-hidden group"
                      >
                        <FaDiscord className="relative z-10 mr-2" />
                        <span className="relative z-10">Discord</span>
                      </DroneforgeButton>
                      
                      <DroneforgeButton 
                        href="https://twitter.com/thedroneforge" 
                        variant="primary" 
                        size="md"
                        className="relative overflow-hidden group"
                      >
                        <FiTwitter className="relative z-10 mr-2" />
                        <span className="relative z-10">X</span>
                      </DroneforgeButton>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          )}
        </AnimatePresence>
        
        {/* Waitlist modal with enhanced animation */}
        {showWaitlist && (
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <motion.div 
              className="absolute inset-0 bg-black/90 backdrop-blur-sm"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              onClick={() => setShowWaitlist(false)}
            />
            <motion.div 
              className="relative z-10"
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ 
                duration: 0.4, 
                ease: [0.22, 1, 0.36, 1]
              }}
            >
              <WaitlistForm onSuccess={() => setShowWaitlist(false)} />
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}; 